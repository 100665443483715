import { IChat } from './../components/chat/models/chat.interface';
import { IPerizia, IResult, IPeriziaReminder, ILiveXpertTime } from './../models/perizia.interface';
import { IIncarico, IFile } from './../models/incarico.interface';
import { IUtente } from '../models/utente.interface';
import { IAnagrafica } from '../models/anagrafica.interface';
import { Router } from '@angular/router';
import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';


import { UserService } from './user.service';
import { Observable } from 'rxjs';
import { IActivity } from 'app/models/activity.interface';
import { ITimeMatrix } from 'app/models/time-matrix.interface';
import { IAPeB, Imot } from 'app/models/apeb.interface';
import { IForm } from 'app/models/form.interface';
import { IDocument } from 'app/models/documents.interface';
import { IDocumentValue } from 'app/models/document-value.interface';
import { ICommessa } from 'app/models/commessa.interface';
import { IIndustrialIspezione } from 'app/models/industrial-ispezione.interface';
import { IMdgGrandine } from 'app/models/mdg-grandine.interface';
import { getEmailDomain } from 'app/shared';
import { ITempario } from 'app/models/tempario-interface';
import { DatePipe } from '@angular/common';
import { IIndustrialOrder } from 'app/models/industrial-order.interface';
import { IAPeBReclamo, IAPeBReclamoDett } from 'app/models/apeb-reclamo.interface';
import { ITravel } from 'app/models/travel.interface';
import { IFirCert } from 'app/models/fir.interface';


@Injectable()
export class MysqlService {

  URL_BASE = '';
  stati_perizia: any[];

  isTest() {
    return this.URL_BASE.includes('-test');
  }

  getLogo() {


    let logo = '';

    if (this.isTest()) logo = '/assets/img/test_logo.png';

    else if (this.URL_BASE.includes('csi.')) logo = '/assets/img/csi_logo.png';
    else if (this.URL_BASE.includes('evolvea')) logo = '/assets/img/ev_logo.png';
    else if (this.URL_BASE.includes('ggroup')) logo = '/assets/img/gg_logo.png';
    else if (this.URL_BASE.includes('tradeone')) logo = '/assets/img/tradeone_logo.png';
    else if (this.URL_BASE.includes('cta-iannone')) logo = '/assets/img/cta-iannone_logo.png';
    else if (this.URL_BASE.includes('expert')) logo = '/assets/img/ie_logo.png';
    else if (this.URL_BASE.includes('demo')) logo = '/assets/img/gg_logo.png';
    else if (this.URL_BASE.includes('t-econsulting')) logo = '/assets/img/te_logo.png';
    else if (this.URL_BASE.includes('rina')) logo = '/assets/img/rina_logo.png';
    else if (this.URL_BASE.includes('nationalclaims')) logo = '/assets/img/nc_logo.png';
    else logo = '/assets/img/test_logo.png';

    //console.log(this.URL_BASE, logo)

    return logo;

  }

  getIcon() {

    if (this.isTest()) return 'test';
    else if (this.URL_BASE.includes('csi.')) return 'csi'
    else if (this.URL_BASE.includes('evolvea')) return 'ev';
    else if (this.URL_BASE.includes('demo.ggroup')) return 'gg-demo';
    else if (this.URL_BASE.includes('ggroup')) return 'gg';
    else if (this.URL_BASE.includes('tradeone')) return 'tradeone';
    else if (this.URL_BASE.includes('cta-iannone')) return 'cta-iannone';
    else if (this.URL_BASE.includes('expert')) return 'ie';
    else if (this.URL_BASE.includes('t-econsulting')) return 'te';
    else if (this.URL_BASE.includes('rina')) return 'ri'
    else if (this.URL_BASE.includes('nationalclaims')) return 'nc'
    return 'test';

  }

  constructor(private http: HttpClient, router: Router, private userService: UserService, @Inject(LOCALE_ID) private _locale: string) {

    this.URL_BASE = window.location.href.split('/').slice(0, 3).join('/');
    let host = window.location.href.split('/')[2].split(':')[0];

    if (host == 'localhost' || host.startsWith('192.168.')) {
      //this.URL_BASE = 'https://xpert-test.i-expert.it/'
      //this.URL_BASE = 'https://xpert.i-expert.it/' 
      //this.URL_BASE = 'https://demo.ggroup.clod/'
      this.URL_BASE = 'https://xpert.ggroup.cloud/'

      this.URL_BASE += 'api/api.php?cmd=';
    } else {
      this.URL_BASE = window.location.href.split('/').slice(0, 3).join('/');
      this.URL_BASE += '/api/api.php?cmd=';
    }

    this.http.get(this.URL_BASE + 'list_perizia_stati').toPromise()
      .then((res: any[]) => {
        this.stati_perizia = res
        localStorage.setItem('stati_perizia', JSON.stringify(res))
      })
      .catch(err => console.error(err))
  }



  photoroom_remove_background(id, attachment) {

    let data = {
      auth: this.userService.getCurrentUser(),
      attachment: attachment,
      id: id
    }

    return this.http.post(this.URL_BASE + 'photoroom_remove_background', data).toPromise();

  }


  authority_list(showOnlyNotValidate): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      showOnlyNotValidate: showOnlyNotValidate
    }
    console.clear()
    console.log(data)
    return this.http.post(this.URL_BASE + 'authority_list', data).toPromise();
  }

  authority_upsert(item): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }
    return this.http.post(this.URL_BASE + 'authority_upsert', data).toPromise();
  }

  authority_import_xls(file: IFile): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      file: file
    }
    return this.http.post(this.URL_BASE + 'authority_import_xls', data).toPromise();
  }


  inventory_list(d = new Date()) {
    let data = {
      auth: this.userService.getCurrentUser(),
      d: d
    }

    return this.http.post(this.URL_BASE + 'inventory_list', data).toPromise();

  }

  perizie_checks_list(): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'perizie_checks_list', data).toPromise();
  }


  conti_mark_as_invoiced(ids: any[], type: string, rif: string): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      ids: ids,
      type: type,
      rif: rif
    }

    return this.http.post(this.URL_BASE + 'conti_mark_as_invoiced', data).toPromise();
  }

  conti_list(id: number, cliente: number, commessa: number, targa: string, dtaStart1, dtaStart2, dtaEnd1, dtaEnd2, assegnatoA1: string, assegnatoA2: string, showOnlyNotPreInvoiced: boolean): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      cliente: cliente,
      commessa: commessa,
      targa: targa,
      dtaStart1: dtaStart1,
      dtaStart2: dtaStart2,
      dtaEnd1: dtaEnd1,
      dtaEnd2: dtaEnd2,
      assegnatoA1: assegnatoA1,
      assegnatoA2: assegnatoA2,
      showOnlyNotPreInvoiced: showOnlyNotPreInvoiced,
    }

    return this.http.post(this.URL_BASE + 'conti_list', data).toPromise();
  }

  aws_chime_get_data(meetingId: string): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId
    }

    return this.http.post(this.URL_BASE + 'aws_chime_get_data', data).toPromise();
  }

  aws_chime_add_question(meetingId: string, domanda: string): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId,
      domanda: domanda
    }

    return this.http.post(this.URL_BASE + 'aws_chime_add_question', data).toPromise();
  }

  aws_chime_add_answer(meetingId: string, domandaId: number, attendeeId: string, val: string, username: string): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId,
      domandaId: domandaId,
      attendeeId: attendeeId,
      val: val,
      username: username,
      date: new Date()
    }

    return this.http.post(this.URL_BASE + 'aws_chime_add_answer', data).toPromise();
  }


  aws_chime_list(): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'aws_chime_list', data).toPromise();
  }


  aws_chime_create_meeting(): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'aws_chime_create_meeting', data).toPromise();
  }

  aws_chime_get_meeting(meetingId: string): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId
    }

    return this.http.post(this.URL_BASE + 'aws_chime_get_meeting', data).toPromise();
  }

  aws_chime_get_attendee(meetingId: string, attendeeId: string): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId,
      attendeeId: attendeeId
    }

    return this.http.post(this.URL_BASE + 'aws_chime_get_attendee', data).toPromise();
  }

  aws_chime_create_attendee(meetingId: string): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId
    }

    return this.http.post(this.URL_BASE + 'aws_chime_create_attendee', data).toPromise();
  }

  aws_chime_update_description(meetingId, description): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId,
      description: description
    }

    return this.http.post(this.URL_BASE + 'aws_chime_update_description', data).toPromise();

  }

  aws_chime_update_allegati(meetingId, allegati): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId,
      allegati: allegati
    }

    return this.http.post(this.URL_BASE + 'aws_chime_update_allegati', data).toPromise();

  }

  aws_chime_update_quiz(meetingId, quiz): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId,
      quiz: quiz
    }

    return this.http.post(this.URL_BASE + 'aws_chime_update_quiz', data).toPromise();

  }

  aws_chime_update_quiz_response(meetingId, quiz, status): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId,
      quiz: quiz,
      status: status
    }

    return this.http.post(this.URL_BASE + 'aws_chime_update_quiz_response', data).toPromise();

  }

  aws_chime_get_quiz(meetingId): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId,
    }

    return this.http.post(this.URL_BASE + 'aws_chime_get_quiz', data).toPromise();

  }

  aws_chime_get_allegati(meetingId): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId,
    }

    return this.http.post(this.URL_BASE + 'aws_chime_get_allegati', data).toPromise();

  }

  aws_chime_update_doc(meetingId, doc): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId,
      doc: doc
    }

    return this.http.post(this.URL_BASE + 'aws_chime_update_doc', data).toPromise();

  }

  aws_chime_get_creator(meetingId): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId,
    }

    return this.http.post(this.URL_BASE + 'aws_chime_get_creator', data).toPromise();

  }


  aws_chime_get_doc(meetingId): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId,
    }

    return this.http.post(this.URL_BASE + 'aws_chime_get_doc', data).toPromise();

  }


  aws_chime_attendee_name_list(meetingId): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId,
    }


    return this.http.post(this.URL_BASE + 'aws_chime_attendee_name_list', data).toPromise();

  }


  aws_chime_attendee_name_upsert(item): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'aws_chime_attendee_name_upsert', data).toPromise();
  }

  xfindcar_import(file: IFile): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      file: file
    }
    return this.http.post(this.URL_BASE + 'xfindcar_import', data).toPromise();
  }

  xfindcar_confirm(id: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }
    return this.http.post(this.URL_BASE + 'xfindcar_confirm', data).toPromise();
  }

  xfindcar_set_id_perizia(id: string, idPerizia: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      idPerizia: idPerizia
    }
    return this.http.post(this.URL_BASE + 'xfindcar_set_id_perizia', data).toPromise();
  }

  xfindcar_get(id): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
    }
    return this.http.post(this.URL_BASE + 'xfindcar_get', data).toPromise();
  }

  xfindcar_list(dtaStart): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      dtaStart: dtaStart,
    }
    return this.http.post(this.URL_BASE + 'xfindcar_list', data).toPromise();
  }

  xfindcar_list_grouped(dtaStart): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      dtaStart: dtaStart,
    }
    return this.http.post(this.URL_BASE + 'xfindcar_list_grouped', data).toPromise();
  }

  xfindcar_set_dta_appuntamento(piazzale, dtaAppuntamento): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      piazzale: piazzale,
      dtaAppuntamento: dtaAppuntamento
    }
    return this.http.post(this.URL_BASE + 'xfindcar_set_dta_appuntamento', data).toPromise();
  }


  send_mail(msg): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      data: msg
    }

    return this.http.post(this.URL_BASE + 'send_mail', data).toPromise();
  }

  getStatDelay(ur, commesse = []) {

    let data = {
      auth: this.userService.getCurrentUser(),
      ur: ur,
      commesse: commesse
    }

    console.log(data)

    return this.http.post(this.URL_BASE + 'get_stats_delay', data).toPromise();

  }

  aws_rekognition_compare_faces(img1, img2) {

    let data = {
      auth: this.userService.getCurrentUser(),
      img1: img1,
      img2: img2
    }

    return this.http.post(this.URL_BASE + 'aws_rekognition_compare_faces', data).toPromise();

  }


  tinyurl_get_tiny_url(url: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      url: url
    }

    return this.http.post(this.URL_BASE + 'tinyurl_get_tiny_url', data).toPromise();

  }

  impostazioni_get(code): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      code: code
    }

    return this.http.post(this.URL_BASE + 'impostazioni_get', data).toPromise();
  }

  impostazioni_upsert(codice, valore): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      codice: codice,
      valore: valore
    }

    return this.http.post(this.URL_BASE + 'impostazioni_upsert', data).toPromise();
  }

  ////////////////////////// APEB RECLAMI /////////////////////////////

  apeb_imported_list(period, d1, d2): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      period: period,
      d1: d1,
      d2: d2
    }

    return this.http.post(this.URL_BASE + 'apeb_imported_list', data).toPromise();
  }

  apeb_reclami_list(y1 = undefined, y2 = undefined): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      y1: y1,
      y2: y2
    }

    return this.http.post(this.URL_BASE + 'apeb_reclami_list', data).toPromise();
  }

  apeb_attach_list(id): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
    }

    return this.http.post(this.URL_BASE + 'apeb_attach_list', data).toPromise();
  }

  apeb_attach_upsert(id, files): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      files: files
    }

    return this.http.post(this.URL_BASE + 'apeb_attach_upsert', data).toPromise();
  }


  apeb_get_attachment_url(id: number, f: IFile, type: string, $fout = '') {
    return `${this.URL_BASE}apeb_get_attachment&id=${id}&hash=${f.hash}&filename=${f.fileName}&type=${type}`;
  }

  apeb_reclami_get(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'apeb_reclami_get', data).toPromise();
  }

  apeb_reclami_upsert(item: IAPeBReclamo): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'apeb_reclami_upsert', data).toPromise();
  }

  apeb_reclami_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }


    return this.http.post(this.URL_BASE + 'apeb_reclami_delete', data).toPromise();
  }


  apeb_reclami_stats(y1 = undefined, y2 = undefined): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      y1: y1,
      y2: y2
    }

    return this.http.post(this.URL_BASE + 'apeb_reclami_stats', data).toPromise();
  }

  apeb_reclami_import_xls(file: IFile): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      file: file
    }

    return this.http.post(this.URL_BASE + 'apeb_reclami_import_xls', data).toPromise();
  }

  apeb_reclami_list_dett(idReclamo = -1, y1 = undefined, y2 = undefined): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: idReclamo,
      y1: y1,
      y2: y2
    }

    return this.http.post(this.URL_BASE + 'apeb_reclami_list_dett', data).toPromise();
  }

  apeb_reclami_upsert_dett(item: IAPeBReclamoDett): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'apeb_reclami_upsert_dett', data).toPromise();
  }

  apeb_reclami_delete_dett(id): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'apeb_reclami_delete_dett', data).toPromise();
  }


  apeb_reclami_get_attachment_url(id: number, f: IFile, $fout = '') {
    return `${this.URL_BASE}apeb_reclami_get_attachment&id=${id}&hash=${f.hash}&filename=${f.fileName}`;
  }


  apeb_reclami_to_XLS(stato) {
    let url = this.URL_BASE + `apeb_reclami_get_table&token=${this.userService.getToken()}&email=${this.userService.getEmail()}&stato=${stato}`;
    return url;
  }


  apeb_reclami_get_pdf_url(id) {
    return this.URL_BASE + `apeb_reclami_get_pdf&token=${this.userService.getToken()}&email=${this.userService.getEmail()}&id=${id}`;
  }

  /////////////////////VERYCAR///////////////////////////
  callPayment(amount, token, id) {

    let data = {
      amount: amount,
      token: token,
      id: id

    }
    return this.http.post(this.URL_BASE + 'verycar_callPaymentToken', data).toPromise();
  }


  verycar_create_perizia(id: string) {
    let data = {
      id: id
    }
    return this.http.post(this.URL_BASE + 'verycar_create_perizia', data).toPromise();
  }

  verycar_getUrl(id: string, dl: boolean) {
    let url = this.URL_BASE + 'verycar_report&id=' + id;
    if (dl) url += '&dl=1';
    return url;
  }

  verycar_defs_get(id: string) {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }
    return this.http.post(this.URL_BASE + 'verycar_defs_get', data).toPromise();
  }

  verycar_defs_upsert(item) {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }
    return this.http.post(this.URL_BASE + 'verycar_defs_upsert', data).toPromise();
  }

  verycar_defs_delete(id) {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }
    return this.http.post(this.URL_BASE + 'verycar_defs_delete', data).toPromise();
  }

  verycar_defs_list() {
    let data = {
      auth: this.userService.getCurrentUser(),
    }
    return this.http.post(this.URL_BASE + 'verycar_defs_list', data).toPromise();
  }

  verycar_users_list() {
    let data = {
      auth: this.userService.getCurrentUser(),
    }
    return this.http.post(this.URL_BASE + 'verycar_users_list', data).toPromise();
  }

  verycar_segnalazioni_list() {
    let data = {
      auth: this.userService.getCurrentUser(),
    }
    return this.http.post(this.URL_BASE + 'verycar_segnalazioni_list', data).toPromise();
  }

  verycar_get(id: string) {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }
    return this.http.post(this.URL_BASE + 'verycar_get', data).toPromise();
  }


  verycar_get_user(email: string) {
    let data = {
      email: email
    }
    return this.http.post(this.URL_BASE + 'verycar_get_user', data).toPromise();
  }

  /////////////////////// valutazioni task ////////////////////////////
  valutazioni_task_list(id, tab): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      tab: tab
    }

    return this.http.post(this.URL_BASE + 'valutazioni_task_list', data).toPromise();
  }

  valutazioni_task_get(id, tab): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      tab: tab
    }
    return this.http.post(this.URL_BASE + 'valutazioni_task_get', data).toPromise();
  }

  valutazioni_task_upsert(item: any): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'valutazioni_task_upsert', data).toPromise();

  }

  valutazioni_task_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'valutazioni_task_delete', data).toPromise();
  }


  ///////////////////////////////////// INDUSTRIAL ISPEZIONI//////////////////////////////////////////////////////

  industrial_ispezioni_accept_reject(id: number, nextStatus: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      nextStatus: nextStatus
    }

    return this.http.post(this.URL_BASE + 'industrial_ispezioni_accept_reject', data).toPromise();
  }



  industrial_ispezioni_list(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'industrial_ispezioni_list', data).toPromise();
  }

  industrial_ispezioni_upsert(item: IIndustrialIspezione): Observable<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    //return this.http.post(this.URL_BASE + 'fir_upsert', data).toPromise();
    const request = new HttpRequest('POST', this.URL_BASE + 'industrial_ispezioni_upsert', data, {
      reportProgress: true
    });

    return this.http.request(request);

  }

  industrial_ispezioni_get(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'industrial_ispezioni_get', data).toPromise();
  }

  industrial_ispezioni_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }


    return this.http.post(this.URL_BASE + 'industrial_ispezioni_delete', data).toPromise();
  }

  industrial_ispezioni_import(id: number, file): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      file: file
    }


    return this.http.post(this.URL_BASE + 'industrial_ispezioni_import', data).toPromise();
  }


  industrial_ispezioni_update_appointments(dirties): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      data: dirties
    }

    return this.http.post(this.URL_BASE + 'industrial_ispezioni_update_appointments', data).toPromise();
  }



  ///////////////////////////////////// INDUSTRIAL ORDERS//////////////////////////////////////////////////////


  industrial_orders_list(tipo: string, ramo: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      tipo: tipo,
      ramo: ramo
    }


    return this.http.post(this.URL_BASE + 'industrial_orders_list', data).toPromise();
  }

  industrial_orders_upsert(item: IIndustrialOrder): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }


    return this.http.post(this.URL_BASE + 'industrial_orders_upsert', data).toPromise();

  }

  industrial_orders_get(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'industrial_orders_get', data).toPromise();
  }

  industrial_orders_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'industrial_orders_delete', data).toPromise();
  }
  industrial_orders_sliceOrder(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'industrial_orders_sliceOrder', data).toPromise();
  }


  industrial_orders_print_request(id) {
    return this.URL_BASE + `industrial_orders_print_request&id=${id}&token=${this.userService.getToken()}&email=${this.userService.getEmail()}`;
  }

  industrial_orders_notify_request(id): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'industrial_orders_notify_request', data).toPromise();
  }


  industrial_orders_print_offer(id) {
    return this.URL_BASE + `industrial_orders_print_offer&id=${id}&token=${this.userService.getToken()}&email=${this.userService.getEmail()}`;
  }

  industrial_orders_notify_offer(id): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'industrial_orders_notify_offer', data).toPromise();
  }



  //////////////////////////////// commesse ////////////////////////////////////////


  commesse_list(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'commesse_list', data).toPromise();
  }

  commesse_list_by_customer(customer): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      customer: customer
    }

    return this.http.post(this.URL_BASE + 'commesse_list_by_customer', data).toPromise();
  }

  commesse_list_current(tipo, cliente): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      tipo: tipo,
      cliente: cliente
    }

    return this.http.post(this.URL_BASE + 'commesse_list_current', data).toPromise();
  }

  commessa_get_attachment_url(id: number, f: IFile, type: string, $fout = '') {
    return `${this.URL_BASE}commesse_get_attachment&id=${id}&hash=${f.hash}&filename=${f.fileName}&type=${type}`;
  }

  commesse_upsert(item: ICommessa): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'commesse_upsert', data).toPromise();
  }

  commesse_get(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'commesse_get', data).toPromise();
  }

  commesse_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'commesse_delete', data).toPromise();
  }

  ////////////////////////// inspector_groups_ ///////////////////

  inspector_groups_list_groups(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'inspector_groups_list_groups', data).toPromise();
  }

  inspector_groups_list_users(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'inspector_groups_list_users', data).toPromise();
  }


  inspector_groups_list_users_by_group(group: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      group: group
    }

    return this.http.post(this.URL_BASE + 'inspector_groups_list_users_by_group', data).toPromise();
  }

  inspector_groups_list_groups_by_user(user: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      user: user
    }

    return this.http.post(this.URL_BASE + 'inspector_groups_list_groups_by_user', data).toPromise();
  }

  inspector_groups_get_user_group_location(zone: string, group: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      zone: zone,
      group: group
    }

    return this.http.post(this.URL_BASE + 'inspector_groups_get_user_group_location', data).toPromise();
  }


  inspector_groups_add_group(group: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      group: group
    }

    return this.http.post(this.URL_BASE + 'inspector_groups_add_group', data).toPromise();
  }



  inspector_groups_upsert(item): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'inspector_groups_upsert', data).toPromise();
  }


  ////////////////////////// CESPITI ///////////////////////////


  notifyWreck(targa: string, id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      targa: targa,
      id: id
    }

    return this.http.post(this.URL_BASE + 'notifyWreck', data).toPromise();
  }


  valutazioni_cespiti_list_by_relation(table: string, id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      table: table,
      id: id
    }

    return this.http.post(this.URL_BASE + 'valutazioni_cespiti_list_by_relation', data).toPromise();
  }

  valutazioni_cespiti_add_relation(tableSelector: string, idCespite: number, idRelatedTable: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      tableSelector: tableSelector,
      idCespite: idCespite,
      idRelatedTable: idRelatedTable
    }

    return this.http.post(this.URL_BASE + 'valutazioni_cespiti_add_relation', data).toPromise();
  }

  valutazioni_cespiti_delete_relation(tableSelector: string, idCespite: number, idRelatedTable: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      tableSelector: tableSelector,
      idCespite: idCespite,
      idRelatedTable: idRelatedTable
    }

    return this.http.post(this.URL_BASE + 'valutazioni_cespiti_delete_relation', data).toPromise();
  }

  valutazioni_cespiti_list(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'valutazioni_cespiti_list', data).toPromise();
  }

  valutazioni_cespiti_get(id): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }
    return this.http.post(this.URL_BASE + 'valutazioni_cespiti_get', data).toPromise();
  }

  valutazioni_cespiti_upsert(item: any): Observable<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    //return this.http.post(this.URL_BASE + 'fir_upsert', data).toPromise();
    const request = new HttpRequest('POST', this.URL_BASE + 'valutazioni_cespiti_upsert', data, {
      reportProgress: true
    });

    return this.http.request(request);
  }

  valutazioni_cespiti_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'valutazioni_cespiti_delete', data).toPromise();
  }

  ////////////////////////// RITIRI ///////////////////////////
  valutazioni_ritiri_list(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'valutazioni_ritiri_list', data).toPromise();
  }

  valutazioni_ritiri_get(id): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }
    return this.http.post(this.URL_BASE + 'valutazioni_ritiri_get', data).toPromise();
  }

  valutazioni_ritiri_upsert(item: any): Observable<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    //return this.http.post(this.URL_BASE + 'fir_upsert', data).toPromise();
    const request = new HttpRequest('POST', this.URL_BASE + 'valutazioni_ritiri_upsert', data, {
      reportProgress: true
    });

    return this.http.request(request);
  }

  valutazioni_ritiri_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'valutazioni_ritiri_delete', data).toPromise();
  }

  valutazioni_ritiri_print_verbale_riconsegna(id, recipient) {
    let url = this.URL_BASE + `valutazioni_ritiri_print_verbale_riconsegna&id=${id}&token=${this.userService.getToken()}&email=${this.userService.getEmail()}&recipient=${recipient}`;
    if (recipient) this.http.get(url).toPromise().then(res => console.log(res)).catch(err => console.error(err));
    else return url;
  }

  valutazioni_ritiri_print_buono_ritiro(id, recipient) {
    let url = this.URL_BASE + `valutazioni_ritiri_print_buono_ritiro&id=${id}&token=${this.userService.getToken()}&email=${this.userService.getEmail()}&recipient=${recipient}`;
    if (recipient) this.http.get(url).toPromise().then(res => console.log(res)).catch(err => console.error(err));
    else return url;
  }


  valutazioni_ritiri_print_scheda_tecnica(id, recipient) {
    let url = this.URL_BASE + `valutazioni_ritiri_print_scheda_tecnica&id=${id}&token=${this.userService.getToken()}&email=${this.userService.getEmail()}&recipient=${recipient}`;
    if (recipient) this.http.get(url).toPromise().then(res => console.log(res)).catch(err => console.error(err));
    else return url;
  }

  valutazioni_ritiri_print_scheda_trasporto(id, recipient) {
    let url = this.URL_BASE + `valutazioni_ritiri_print_scheda_trasporto&id=${id}&token=${this.userService.getToken()}&email=${this.userService.getEmail()}&recipient=${recipient}`;
    if (recipient) this.http.get(url).toPromise().then(res => console.log(res)).catch(err => console.error(err));
    else return url;
  }

  valutazioni_ritiri_get_attachment_url(id: number, f: IFile, type: string, $fout = '') {
    return `${this.URL_BASE}valutazioni_ritiri_get_attachment&id=${id}&hash=${f.hash}&filename=${f.fileName}&type=${type}`;
  }


  valutazioni_ritiri_get_export_url() {
    return this.URL_BASE + `valutazioni_ritiri_export&token=${this.userService.getToken()}&email=${this.userService.getEmail()}`;
  }

  valutazioni_ritiri_import(file): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      file: file
    }

    return this.http.post(this.URL_BASE + 'valutazioni_ritiri_import', data).toPromise();
  }


  ////////////////////////// VALUITAZIONI ///////////////////////////

  valutazioni_list(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'valutazioni_list', data).toPromise();
  }

  valutazioni_get_attachment_url(id: number, f: IFile, type: string, $fout = '') {
    return `${this.URL_BASE}valutazioni_get_attachment&id=${id}&hash=${f.hash}&filename=${f.fileName}&type=${type}`;
  }

  valutazioni_get(id): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }
    return this.http.post(this.URL_BASE + 'valutazioni_get', data).toPromise();
  }

  valutazioni_upsert(item: any): Observable<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    //return this.http.post(this.URL_BASE + 'fir_upsert', data).toPromise();
    const request = new HttpRequest('POST', this.URL_BASE + 'valutazioni_upsert', data, {
      reportProgress: true
    });

    return this.http.request(request);
  }

  valutazioni_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }


    return this.http.post(this.URL_BASE + 'valutazioni_delete', data).toPromise();
  }

  valutazioni_print(id) {
    return this.URL_BASE + `valutazioni_print&id=${id}&token=${this.userService.getToken()}&email=${this.userService.getEmail()}`;
  }

  valutazioni_get_export_url() {
    return this.URL_BASE + `valutazioni_export&token=${this.userService.getToken()}&email=${this.userService.getEmail()}`;
  }


  ////////////////////////// CHECKS ///////////////////////////

  checks_list(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'checks_list', data).toPromise();
  }


  checks_get(id): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }
    return this.http.post(this.URL_BASE + 'checks_get', data).toPromise();
  }


  checks_upsert(item: any): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }
    return this.http.post(this.URL_BASE + 'checks_upsert', data).toPromise();
  }


  checks_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }
    return this.http.post(this.URL_BASE + 'checks_delete', data).toPromise();
  }


  //////////////////////////// DOCUMENTS //////////////////////////


  documents_upsert_value(item: IDocumentValue): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }


    return this.http.post(this.URL_BASE + 'documents_upsert_value', data).toPromise();
  }

  documents_get_value(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }


    return this.http.post(this.URL_BASE + 'documents_get_value', data).toPromise();
  }


  documents_list(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'documents_list', data).toPromise();
  }

  documents_upsert(item: IDocument): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'documents_upsert', data).toPromise();
  }

  documents_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'documents_delete', data).toPromise();
  }

  documents_get(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'documents_get', data).toPromise();
  }


  ///////////////////////////////////////////////////////////////////////////////////////////

  forms_save_value(id, value): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      value: value
    }


    return this.http.post(this.URL_BASE + 'forms_save_value', data).toPromise();
  }

  forms_get_print_url(id, hash) {
    return this.URL_BASE + `forms_print&token=${this.userService.getToken()}&email=${this.userService.getEmail()}&id=${id}&hash=${hash}`;
  }

  forms_list(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }


    return this.http.post(this.URL_BASE + 'forms_list', data).toPromise();
  }

  forms_list_cc(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'forms_list_cc', data).toPromise();
  }

  forms_list_mod(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'forms_list_mod', data).toPromise();
  }

  forms_upsert(item: IForm): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'forms_upsert', data).toPromise();
  }

  forms_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }


    return this.http.post(this.URL_BASE + 'forms_delete', data).toPromise();
  }

  forms_get(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }


    return this.http.post(this.URL_BASE + 'forms_get', data).toPromise();

  }

  forms_get_by_code(code: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      code: code
    }


    return this.http.post(this.URL_BASE + 'forms_get_by_code', data).toPromise();

  }



  //////////////////// ARCHIVE ///////////////////////////
  archive_list(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser()
    }

    // console.log(data)
    return this.http.post(this.URL_BASE + 'archive_list', data).toPromise();
  }

  archive_get(client): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      client: client
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'archive_get', data).toPromise();
  }

  archive_upsert(item, recipients): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item,
      recipients: recipients
    }

    return this.http.post(this.URL_BASE + 'archive_upsert', data).toPromise();
  }

  archive_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }


    return this.http.post(this.URL_BASE + 'archive_delete', data).toPromise();
  }

  archive_get_attachment_url(id: number, f: IFile, type: string, $fout = '') {
    return `${this.URL_BASE}archive_get_attachment&id=${id}&hash=${f.hash}&filename=${f.fileName}&type=${type}`;
  }


  /////////////////// TIME MATRIX ////////////////////////


  mdg_grandine_list(dayLimit: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      dayLimit: dayLimit,
    }


    return this.http.post(this.URL_BASE + 'mdg_grandine_list', data).toPromise();
  }

  mdg_grandine_get(id): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }


    return this.http.post(this.URL_BASE + 'mdg_grandine_get', data).toPromise();
  }

  mdg_grandine_get_stats(period, user): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      period: period,
      user: user,
    }


    return this.http.post(this.URL_BASE + 'mdg_grandine_get_stats', data).toPromise();
  }


  mdg_grandine_upsert(item: IMdgGrandine): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'mdg_grandine_upsert', data).toPromise();
  }

  mdg_grandine_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }


    return this.http.post(this.URL_BASE + 'mdg_grandine_delete', data).toPromise();
  }


  mdg_grandine_list_changes(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'mdg_grandine_list_changes', data).toPromise();
  }

  mdg_grandine_get_export_url() {
    let url = this.URL_BASE + `mdg_grandine_export&token=${this.userService.getToken()}&email=${this.userService.getEmail()}`;
    return url;
  }

  apeb_get_carrozzeria(id) {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }
    return this.http.post(this.URL_BASE + 'apeb_get_carrozzeria', data).toPromise();

  }
  apeb_get_note_carrozzeria(id) {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }
    return this.http.post(this.URL_BASE + 'apeb_get_note_carrozzeria', data).toPromise();

  }

  //////////////////////////APEB RINDEX/////////////////////////////////////////
  apeb_get_rindex(user, date) {
    let data = {
      auth: this.userService.getCurrentUser(),
      user: user,
      date: date
    }
    return this.http.post(this.URL_BASE + 'apeb_get_rindex', data).toPromise();
  }
  apeb_set_rindex(id, user, usr_carico, usr_lavorabili, ur_carico, ur_lavorabili, date) {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      date: date,
      user: user,
      usr_carico: usr_carico,
      usr_lavorabili: usr_lavorabili,
      ur_carico: ur_carico,
      ur_lavorabili: ur_lavorabili
    }
    return this.http.post(this.URL_BASE + 'apeb_set_rindex', data).toPromise();
  }
  apeb_get_rindex_values(date) {
    let data = {
      auth: this.userService.getCurrentUser(),
      date: date
    }
    return this.http.post(this.URL_BASE + 'apeb_get_rindex_values', data).toPromise();
  }

  apeb_rindexStats(date) {
    let data = {
      auth: this.userService.getCurrentUser(),
      date: date
    }
    return this.http.post(this.URL_BASE + 'apeb_rindexStats', data).toPromise();
  }

  apeb_set_rindex_values(id, incarichi_17, nuove_lavorate, pregresso_lavorate, nuove_da_lavorare, date, ur_lavorate, ur_portale, ur_nuove_lavorare, carico_sala, recupero_cai, indicatore_dashboard) {
    let item = {
      id: id,
      incarichi_17: incarichi_17,
      nuove_lavorate: nuove_lavorate,
      pregresso_lavorate: pregresso_lavorate,
      nuove_da_lavorare: nuove_da_lavorare,
      date: date,
      ur_lavorate: ur_lavorate,
      ur_portale: ur_portale,
      ur_nuove_lavorare: ur_nuove_lavorare,
      carico_sala: carico_sala,
      recupero_cai: recupero_cai,
      indicatore_dashboard: indicatore_dashboard
    }
    let data = {
      item: item,
      auth: this.userService.getCurrentUser()
    }

    return this.http.post(this.URL_BASE + 'apeb_set_rindex_values', data).toPromise();
  }
  /////////////////////////////

  apeb_get_stats_inlav(d1, d2, client) {
    let data = {
      auth: this.userService.getCurrentUser(),
      d1: d1,
      d2: d2,
      client: client
    }
    return this.http.post(this.URL_BASE + 'apeb_get_stats_inlav', data).toPromise();
  }

  apeb_list(client: number, dayLimit: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      dayLimit: dayLimit,
      client: client
    }

    return this.http.post(this.URL_BASE + 'apeb_list', data).toPromise();
  }


  apeb_export_ultimi_tagli(client: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      client: client
    }

    return this.http.post(this.URL_BASE + 'apeb_export_ultimi_tagli', data, {
      responseType: "blob",
      headers: new HttpHeaders().append("Content-Type", "application/json")
    }).toPromise()

  }

  apeb_list_ultimi_tagli(client: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      client: client
    }

    return this.http.post(this.URL_BASE + 'apeb_list_ultimi_tagli', data).toPromise();
  }

  apeb_get(id): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'apeb_get', data).toPromise();
  }

  apeb_upsert(item: IAPeB): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'apeb_upsert', data).toPromise();
  }

  apeb_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'apeb_delete', data).toPromise();
  }

  apeb_get_stats(client: number, period, user, d1: Date, d2: Date, stype: any[]): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      period: period,
      user: user,
      client: client,
      d1: d1,
      d2: d2,
      stype: stype.map(e => e.item_id)
    }

    return this.http.post(this.URL_BASE + 'apeb_get_stats', data).toPromise();
  }

  apeb_get_stats1(client: number, period, user, d1: Date, d2: Date, stype: any[]): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      period: period,
      user: user,
      client: client,
      d1: d1,
      d2: d2,
      stype: stype.map(e => e.item_id)
    }

    return this.http.post(this.URL_BASE + 'apeb_get_stats1', data).toPromise();
  }

  apeb_get_stats_lav(client: number, period, user, d1: Date, d2: Date, type: any[]): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      period: period,
      user: user,
      client: client,
      d1: d1,
      d2: d2,
      type: type.map(e => e.item_id)
    }

    return this.http.post(this.URL_BASE + 'apeb_get_stats_lav', data).toPromise();
  }


  apeb_get_stats_lav1(client: number, user, d1: Date, d2: Date, type: any[]): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      user: user,
      client: client,
      d1: d1,
      d2: d2,
      type: type.map(e => e.item_id)
    }

    console.clear()
    console.log(data)

    return this.http.post(this.URL_BASE + 'apeb_get_stats_lav1', data).toPromise();
  }

  apeb_get_stats_lav_rt(client: number, d1 = null, d2 = null, u: string = null): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      client: client,
      d1: d1,
      d2: d2,
      u: u,
    }

    console.log(data)


    return this.http.post(this.URL_BASE + 'apeb_get_stats_lav_rt', data).toPromise();
  }

  apeb_get_stats_performance(client: number, d1 = null, d2 = null, u: string = null): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      client: client,
      d1: d1,
      d2: d2,
      u: u,
    }

    return this.http.post(this.URL_BASE + 'apeb_get_stats_performance', data).toPromise();
  }

  apeb_daily_get(date): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      user: this.userService.getEmail(),
      date: date
    }


    return this.http.post(this.URL_BASE + 'apeb_daily_get', data).toPromise();
  }


  apeb_get_list_rt_users(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }


    return this.http.post(this.URL_BASE + 'apeb_get_list_rt_users', data).toPromise();
  }

  apeb_daily_upsert(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      value: {}
    }


    return this.http.post(this.URL_BASE + 'apeb_daily_upsert', data).toPromise();
  }


  apeb_get_stats_lav1_not_grouped(client: number, user, d1: Date, d2: Date, type: any[]): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      user: user,
      client: client,
      d1: d1,
      d2: d2,
      type: type.map(e => e.item_id)
    }

    return this.http.post(this.URL_BASE + 'apeb_get_stats_lav1_not_grouped', data).toPromise();
  }
  apeb_list_calls(client: number, period, user, d1: Date, d2: Date, direction): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      period: period,
      user: user,
      client: client,
      d1: d1,

      d2: d2,
      direction: direction
    }

    return this.http.post(this.URL_BASE + 'apeb_list_calls', data).toPromise();
  }


  apeb_get_log_motiv(client: number, user, dta: Date): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      utente: user,
      client: client,
      dta: dta,
    }

    return this.http.post(this.URL_BASE + 'apeb_get_log_motiv', data).toPromise();

  }

  apeb_get_user_log(client: number, dta: Date): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),

      client: client,
      dta: dta,
    }

    return this.http.post(this.URL_BASE + 'apeb_get_user_log', data).toPromise();

  }

  apeb_to_XLS(client, d1, d2, utente) {
    let url = this.URL_BASE + `apeb_get_stats_lav1_not_grouped&token=${this.userService.getToken()}&email=${this.userService.getEmail()}&client=${client}&d1=${d1}&d2=${d2}&utente=${utente}`;
    return url;
  }



  apeb_list_changes(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'apeb_list_changes', data).toPromise();
  }

  apeb_list_lav(sinistro: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      sinistro: sinistro
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'apeb_list_lav', data).toPromise();
  }


  apeb_import_ext(file: IFile): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      file: file
    }

    return this.http.post(this.URL_BASE + 'apeb_import_ext', data).toPromise();
  }

  apeb_import_vox(file: IFile): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      file: file
    }

    return this.http.post(this.URL_BASE + 'apeb_import_vox', data).toPromise();
  }

  apeb_import_solleciti(file: IFile): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      file: file
    }

    return this.http.post(this.URL_BASE + 'apeb_import_solleciti', data).toPromise();
  }

  apeb_get_export_url(client, dtaStart, dtaEnd) {

    let url = this.URL_BASE + `apeb_export&token=${this.userService.getToken()}&email=${this.userService.getEmail()}&client=${client}&dtaStart=${dtaStart}&dtaEnd=${dtaEnd}`;
    return url;
  }


  apeb_insert_motivazioni(item: Imot): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'apeb_insert_motivazioni', data).toPromise();
  }
  apeb_get_user_lav() {
    let data = {
      auth: this.userService.getCurrentUser(),
    }
    return this.http.post(this.URL_BASE + 'apeb_get_user_lav', data).toPromise();
  }

  /////////////////////////////////////////////////////////////////////


  /////////////////// TIME MATRIX ////////////////////////

  time_matrix_list(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'time_matrix_list', data).toPromise();
  }

  time_matrix_upsert(item: ITimeMatrix): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'time_matrix_upsert', data).toPromise();
  }

  time_matrix_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'time_matrix_delete', data).toPromise();
  }


  time_matrix_get_time_required(activity: string, subactivity: string, customer: string, commessa: string): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      activity: !activity ? '' : activity,
      subactivity: !subactivity ? '' : subactivity.replace('perizie', 'insurance'),
      customer: !customer ? 0 : customer,
      commessa: !commessa ? 0 : commessa
    }

    return this.http.post(this.URL_BASE + 'time_matrix_get_time_required', data).toPromise();
  }


  /////////////////////////////////////////////////////////////////////


  /////////////////// TEMPARIO ////////////////////////

  tempario_list(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'tempario_list', data).toPromise();
  }

  tempario_upsert(item: ITempario): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'tempario_upsert', data).toPromise();
  }

  tempario_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'tempario_delete', data).toPromise();
  }






  /////////////////////////////////////////////////////////////////



  ////////////////////// BING MAPS ////////////////////////////////

  bingmaps_optimize(request) {

    let data = {
      auth: this.userService.getCurrentUser(),
      request: request
    }

    return this.http.post(this.URL_BASE + 'bingmaps_optimize', data).toPromise()

  }

  bingmaps_get_nearest_inspector(group, lat, lon): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      group: group,
      origin: {
        latitude: lat,
        longitude: lon
      }
    }

    return this.http.post(this.URL_BASE + 'bingmaps_get_nearest_inspector', data).toPromise()

  }


  bingmaps_cache: any = {}
  bingmaps_search(query: string) {


    let data = {
      auth: this.userService.getCurrentUser(),
      query: query
    }


    return this.http.post(this.URL_BASE + 'bingmaps_search', data).toPromise()

  }


  bingmaps_reverse_search(location) {


    let data = {
      auth: this.userService.getCurrentUser(),
      location: location
    }


    return this.http.post(this.URL_BASE + 'bingmaps_reverse_search', data).toPromise()

  }

  bingmaps_optimize_get_result(callbackUrl: string) {

    return this.http.get(callbackUrl).toPromise()

  }

  /////////////////////////////////////////////////////////////////

  ///////////////////////// SMS ///////////////////////////////////
  sms_send(dest: string, msg: string, send: string, return_id = 0): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      dest: dest.replace('+', ''),
      msg: msg,
      send: send,
      return_id: return_id
    }

    return this.http.post(this.URL_BASE + 'sms_send', data).toPromise();
  }

  sms_get_status(id: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'sms_get_status', data).toPromise();
  }

  ///////////////////////// FIR ///////////////////////////////////

  fir_cert_list(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'fir_cert_list', data).toPromise();
  }

  fir_cert_upsert(item: IFirCert): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }


    return this.http.post(this.URL_BASE + 'fir_cert_upsert', data).toPromise();
  }
  fir_cert_get(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'fir_cert_list', data).toPromise();
  }


  fir_get_cert_url(id: number) {
    return `${this.URL_BASE}fir_cert_pdf&id=${id}`//&hash=${f.hash}&filename=${f.fileName}&type=${type}`;

  }


  fir_list(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'fir_list', data).toPromise();
  }

  fir_upsert(item: any): Observable<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    //return this.http.post(this.URL_BASE + 'fir_upsert', data).toPromise();
    const request = new HttpRequest('POST', this.URL_BASE + 'fir_upsert', data, {
      reportProgress: true
    });

    return this.http.request(request);
  }



  fir_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'fir_delete', data).toPromise();
  }

  fir_get(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'fir_get', data).toPromise();
  }

  fir_stats(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'fir_stats', data).toPromise();
  }

  fir_get_attachment_url(id: number, f: IFile, type: string, $fout = '') {
    return `${this.URL_BASE}fir_get_attachment&id=${id}&hash=${f.hash}&filename=${f.fileName}&type=${type}`;
  }

  fir_get_export_url() {
    return this.URL_BASE + `fir_export&token=${this.userService.getToken()}&email=${this.userService.getEmail()}`;
  }



  fir_print_pdf(id) {
    return this.URL_BASE + `fir_print_pdf&id=${id}&token=${this.userService.getToken()}&email=${this.userService.getEmail()}`;
  }


  //////////////////////// iRepair /////////////////////////////////////

  irepair_get_result_image_url(img: string) {
    return `${this.URL_BASE}irepair_get_result_image_url&img=${img}`;
  }

  irepair_get_attachment_url(id: number, f: IFile, type: string, $fout = '') {
    return `${this.URL_BASE}irepair_get_attachment_url&id=${id}&hash=${f.hash}&filename=${f.fileName}&type=${type}`;
  }

  irepair_list_storico(id: number) {
    return this.list_storico('perizie', id)
  }

  irepair_list_logs(id: number) {
    return this.list_logs('irepair', id)
  }


  irepair_list(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'irepair_list', data).toPromise();
  }

  irepair_get(id): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
    }

    return this.http.post(this.URL_BASE + 'irepair_get', data).toPromise();
  }

  irepair_upsert(item: any): Observable<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }
    const request = new HttpRequest('POST', this.URL_BASE + 'irepair_upsert', data, {
      reportProgress: true
    });



    return this.http.request(request);
  }

  irepair_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'irepair_delete', data).toPromise();
  }


  irepair_get_result(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'irepair_get_result', data).toPromise();
  }

  irepair_upsert_result(id: number, result: IResult): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      result: result
    }

    return this.http.post(this.URL_BASE + 'irepair_upsert_result', data).toPromise();
  }


  ///////////////////////////// VISITE ////////////////////////////////////////////

  medical_visite_get_result_image_url(img: string) {
    return `${this.URL_BASE}medical_visite_get_result_image_url&img=${img}`;
  }

  medical_visite_get_attachment_url(id: number, f: IFile, type: string, $fout = '') {
    return `${this.URL_BASE}medical_visite_get_attachment_url&id=${id}&hash=${f.hash}&filename=${f.fileName}&type=${type}`;
  }

  medical_visite_list_storico(id: number) {
    return this.list_storico('medical_visite', id)
  }

  medical_visite_list_logs(id: number) {
    return this.list_logs('medical_visite', id)
  }


  medical_visite_list(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'medical_visite_list', data).toPromise();
  }

  medical_visite_get(id): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
    }

    return this.http.post(this.URL_BASE + 'medical_visite_get', data).toPromise();
  }

  medical_visite_upsert(item: any): Observable<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }
    const request = new HttpRequest('POST', this.URL_BASE + 'medical_visite_upsert', data, {
      reportProgress: true
    });



    return this.http.request(request);
  }

  medical_visite_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'medical_visite_delete', data).toPromise();
  }





  medical_visite_get_result(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'medical_visite_get_result', data).toPromise();
  }

  medical_visite_upsert_result(id: number, result: IResult): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      result: result
    }

    return this.http.post(this.URL_BASE + 'medical_visite_upsert_result', data).toPromise();
  }
  //////////////////////// REMARKETING ///////////////////////////////////////

  remarketing_user_list(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'remarketing_user_list', data).toPromise();
  }

  remarketing_user_upsert(item): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'remarketing_user_upsert', data).toPromise();
  }

  remarketing_user_delete(id): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'remarketing_user_delete', data).toPromise();
  }


  remarketing_manage_list(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'remarketing_manage_list', data).toPromise();
  }

  remarketing_manage_list_permissions(id): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'remarketing_manage_list_permissions', data).toPromise();
  }

  remarketing_list(filter): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      filter: filter
    }

    return this.http.post(this.URL_BASE + 'remarketing_list', data).toPromise();
  }

  remarketing_upsert(item): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'remarketing_upsert', data).toPromise();
  }

  remarketing_upsert_lot(item, ids): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item,
      ids: ids
    }

    return this.http.post(this.URL_BASE + 'remarketing_upsert_lot', data).toPromise();
  }


  remarketing_get(id, clearNotifies: boolean): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      filter: { id: id },
      clearNotifies: clearNotifies
    }

    return this.http.post(this.URL_BASE + 'remarketing_get', data).toPromise();
  }

  remarketing_buy(id): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'remarketing_buy', data).toPromise();
  }

  remarketing_raise(id: number, value: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      filter: { id: id }, // usefull for server get
      value: value
    }

    return this.http.post(this.URL_BASE + 'remarketing_raise', data).toPromise();
  }

  //////////////////////// fleet ///////////////////////////////////////

  fleet_update_dta(id, dtaField): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      dtaField: dtaField
    }

    return this.http.post(this.URL_BASE + 'fleet_update_dta', data).toPromise();
  }

  fleet_generate_perizia(id): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'fleet_generate_perizia', data).toPromise();
  }

  fleet_list(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'fleet_list', data).toPromise();
  }

  fleet_upsert(item: any): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'fleet_upsert', data).toPromise();
  }

  fleet_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'fleet_delete', data).toPromise();
  }

  //////////////////////// verycar ///////////////////////////////////////


  verycar_upload_file(id, type, content) {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      type: type,
      content: content,
    }

    return this.http.post(this.URL_BASE + 'verycar_upload_file', data).toPromise();

  }

  verycar_assign_to(id: string, inspector: string, item): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      inspector: inspector,
      item: item,
    }

    return this.http.post(this.URL_BASE + 'verycar_assign_to', data).toPromise();
  }


  verycar_list(light = false): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      light: light
    }

    return this.http.post(this.URL_BASE + 'verycar_list', data).toPromise();
  }

  verycar_list_utenti(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'verycar_list_utenti', data).toPromise();

  }

  verycar_list_utenti_fb(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'verycar_list_utenti_fb', data).toPromise();

  }

  verycar_get_leeds(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'verycar_get_leeds', data).toPromise();

  }

  verycar_send_leed(recipients, code): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      recipients: recipients,
      code: code
    }

    return this.http.post(this.URL_BASE + 'verycar_send_leeds', data).toPromise();

  }

  verycar_get_leed_data(id): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }
    return this.http.post(this.URL_BASE + 'verycar_get_leed_data', data).toPromise();

  }

  verycar_leed_export_xls(code): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: code
    }
    return this.http.post(this.URL_BASE + 'verycar_leed_export_xls', data).toPromise();

  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  telexpert_upsert_allegato(item, requestId, geo = undefined): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      requestId: requestId,
      item: item,
      geo: geo
    }

    return this.http.post(this.URL_BASE + 'telexpert_upsert_allegato', data).toPromise();
  }

  telexpert_get_attachment_url(id: string, f: IFile, type: string = '', $fout = '') {
    return `${this.URL_BASE}telexpert_get_attachment_url&id=${id}&hash=${f.hash}&filename=${f.fileName}`;
  }

  telexpert_send_cloud_message(message): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      message: message
    }

    return this.http.post(this.URL_BASE + 'telexpert_send_cloud_message', data).toPromise();
  }

  telexpert_get_videochat_url(id: string) {
    return `${this.URL_BASE}/videochat-ext/${id}`;
  }


  telexpert_get_all_times() {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'telexpert_get_all_times', data).toPromise();
  }

  telexpert_list_times() {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'telexpert_list_times', data).toPromise();
  }


  telexpert_set_time(item: ILiveXpertTime) {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'telexpert_set_time', data).toPromise();
  }









  /////////////////////////////////////////////////////////////////////////////////////////

  global_get_all_times() {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'global_get_all_times', data).toPromise();
  }

  global_list_times(dbname: string) {
    let data = {
      auth: this.userService.getCurrentUser(),
      dbname: dbname
    }

    return this.http.post(this.URL_BASE + 'global_list_times', data).toPromise();
  }
  ///////////////////////////////////////////////////////////////////////////////////////////

  opentok_create_session(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'opentok_create_session', data).toPromise();
  }

  opentok_generate_token(sessionId: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      sessionId: sessionId
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'opentok_generate_token', data).toPromise();
  }

  opentok_archive_start(sessionId: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      sessionId: sessionId
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'opentok_archive_start', data).toPromise();
  }

  opentok_archive_stop(archiveId: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      archiveId: archiveId
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'opentok_archive_stop', data).toPromise();
  }

  opentok_archive_get(archiveId: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      archiveId: archiveId
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'opentok_archive_get', data).toPromise();
  }
  opentok_archive_get_thumb(archiveId: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      archiveId: archiveId
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'opentok_archive_get_thumb', data).toPromise();
  }

  calendar_upsert_travel(item: ITravel): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'calendar_upsert_travel', data).toPromise();
  }

  calendar_list_travel(agent, dta): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      agent: agent,
      d1: dta,
      allmonth: true
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'calendar_list_travel', data).toPromise();
  }

  calendar_list(type, noLoc = false): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      type: type,
      noLoc: noLoc
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'calendar_list', data).toPromise();
  }

  calendar_get_next(email): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      email: email
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'calendar_get_next', data).toPromise();
  }

  ///////////////////////////////////////////////////////////////////////////////////////////


  rel_activity_user_list(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'rel_activity_user_list', data).toPromise();
  }

  rel_activity_user_upsert(item: IActivity): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'rel_activity_user_upsert', data).toPromise();
  }

  rel_activity_user_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }


    return this.http.post(this.URL_BASE + 'rel_activity_user_delete', data).toPromise();
  }

  ///////////////////////////////////////////////////////////////////////////////////////////


  activity_list(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'activity_list', data).toPromise();
  }

  activity_upsert(item: IActivity): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'activity_upsert', data).toPromise();
  }

  activity_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }


    return this.http.post(this.URL_BASE + 'activity_delete', data).toPromise();
  }

  ///////////////////////////////////////////////////////////////////////////////////////////


  etax_get_valutazione(codice_eurotax: string, tipo_veicolo: string, anno, mese, km) {

    let data = {
      auth: this.userService.getCurrentUser(),
      codice_eurotax: codice_eurotax,
      tipo_veicolo: tipo_veicolo,
      anno: anno,
      mese: mese,
      km: km
    }

    return this.http.post(this.URL_BASE + 'etax_get_valutazione', data).toPromise();
  }

  etax_search_plate(plate: string) {

    let data = {
      auth: this.userService.getCurrentUser(),
      plate: plate
    }

    return this.http.post(this.URL_BASE + 'etax_search_plate', data).toPromise();
  }

  etax_list_marche(tipo, skipAuth = false): Promise<any> {
    let data = {
      auth: !skipAuth ? this.userService.getCurrentUser() : 'skip',
      tipo_veicolo: tipo
    }

    return this.http.post(this.URL_BASE + 'etax_list_marche', data).toPromise();
  }

  etax_list_anni(marca: string, skipAuth = false): Promise<any> {
    let data = {
      auth: !skipAuth ? this.userService.getCurrentUser() : 'skip',
      marca: marca,
      skipAuth: skipAuth
    }

    return this.http.post(this.URL_BASE + 'etax_list_anni', data).toPromise();
  }

  etax_list_modelli(marca: string, anno: string, tipo_veicolo: string, skipAuth = false): Promise<any> {
    let data = {
      auth: !skipAuth ? this.userService.getCurrentUser() : 'skip',
      marca: marca,
      anno: anno,
      tipo_veicolo: tipo_veicolo,
      skipAuth: skipAuth
    }

    return this.http.post(this.URL_BASE + 'etax_list_modelli', data).toPromise();
  }

  etax_list_versioni(marca: string, anno: string, modello: string, tipo_veicolo: string, skipAuth = false): Promise<any> {
    let data = {
      auth: !skipAuth ? this.userService.getCurrentUser() : 'skip',
      marca: marca,
      anno: anno,
      modello: modello,
      tipo_veicolo: tipo_veicolo,
      skipAuth: skipAuth
    }

    return this.http.post(this.URL_BASE + 'etax_list_versioni', data).toPromise();
  }

  ///////////////////////////////////////////////////////////////////////////////////////////

  list_province(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser()
    }

    return this.http.post(this.URL_BASE + 'list_province', data).toPromise();
  }

  list_comuni(prov: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      prov: prov
    }

    return this.http.post(this.URL_BASE + 'list_comuni', data).toPromise();
  }

  ///////////////////////////////////////////////////////////////////////////////////////////

  get_print_adz(id: number, download: boolean) {
    return this.URL_BASE + 'print_adz&header=' + this.getIcon() + '&id=' + id + (download ? '&download=1' : '');
  }

  get_print_adz_perizie(id: number, download: boolean, hash: string, header = this.getIcon()) {
    return this.URL_BASE + 'print_adz_perizie&header=' + header + '&id=' + id + '&hash=' + hash + (download ? '&download=1' : '');
  }

  get_export_url() {
    return this.URL_BASE + 'export';
  }

  get_exp_tab1_url(id: number) {
    return this.URL_BASE + 'exp_tab1&id=' + id;
  }

  get_print_tab1_url(id: number) {
    return this.URL_BASE + 'print_tab1&id=' + id;
  }


  //////////////////////////////////////////////////////////////////////////////////////////

  perzie_adz_update_field(idPerizia: number, field: string, value: any) {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: idPerizia,
      field: field,
      value: value
    }

    return this.http.post(this.URL_BASE + 'perzie_adz_update_field', data).toPromise();

  }

  perizia_convert_input_file(file: IFile): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      file: file
    }

    return this.http.post(this.URL_BASE + 'perizia_convert_input_file', data).toPromise();
  }

  perizie_list_stessa_targa(id, targa): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      targa: targa,
      id: id
    }

    console.log(data)

    return this.http.post(this.URL_BASE + 'perizie_list_stessa_targa', data).toPromise();
  }

  perizia_convert_concordato_file(sel, dest, importo, id): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      sel: sel,
      dest: dest,
      importo: importo,
      id: id
    }

    return this.http.post(this.URL_BASE + 'perizia_convert_concordato_file', data).toPromise();
  }

  perizie_get_parcella(id, importo: number) {
    return this.URL_BASE + `perizie_get_parcella&token=${this.userService.getToken()}&email=${this.userService.getEmail()}&header=${this.getIcon()}&id=${id}&importo=${Math.round(importo * 100)}`;
  }

  perizie_download_inter(user: string, dta: Date, description: string) {

    let p = new DatePipe('it-it')
    let d = p.transform(dta, 'yyyy-MM-dd')

    return this.URL_BASE + `perizie_download_inter&token=${this.userService.getToken()}&email=${this.userService.getEmail()}&header=${this.getIcon()}&logo=${this.getLogo()}&user=${user}&dta=${d}&description=${description}`;

  }



  perizie_list_adz(id) {
    return `${this.URL_BASE}perizie_list_adz&id=${id}&token=${this.userService.getToken()}&email=${this.userService.getEmail()}`;
  }

  perizie_claim_nr_exists(claimNr: string, id: number, cliente: number) {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      claimNr: claimNr,
      cliente: cliente
    }

    return this.http.post(this.URL_BASE + 'perizie_claim_nr_exists', data).toPromise();

  }

  perizia_upsert_result(id: number, result: IResult): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      result: result
    }

    return this.http.post(this.URL_BASE + 'perizia_upsert_result', data).toPromise();

  }

  perizie_update_adz(item: IPerizia): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: item.id,
      adz_data: item.adz_data,
    }

    return this.http.post(this.URL_BASE + 'perizie_update_adz', data).toPromise();

  }

  perizia_upsert_proposte(id: number, proposte, iban_proposta): Promise<any> {
    let data = {
      id: id,
      proposte: proposte,
      iban_proposta: iban_proposta
    }

    return this.http.post(this.URL_BASE + 'perizia_upsert_proposte', data).toPromise();

  }

  perizia_blur(id: number, opts: any): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      opts: opts
    }

    return this.http.post(this.URL_BASE + 'perizia_blur', data).toPromise();

  }


  perizie_reminder_list(filtroUtente, idPerizia = -1): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: idPerizia,
      filtroUtente: filtroUtente
    }

    return this.http.post(this.URL_BASE + 'perizie_reminder_list', data).toPromise();
  }

  perizie_reminder_upsert(item: IPeriziaReminder): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'perizie_reminder_upsert', data).toPromise();
  }

  perizie_reminder_delete(id): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'perizie_reminder_delete', data).toPromise();
  }


  async perizie_send_parcel(item: IPerizia, urls: any[]): Promise<any> {

    let sender = '';
    await this.get_current_user_profile().then(res => {


      sender = res[0].Email2

    })


    let recipient = '';
    let cc = `${sender};s.iannone` + getEmailDomain(this.getIcon()); // fix multidominio per spostare drivenow su gg

    if (!this.isTest()) {
      recipient = 'repair.south@share-now.com; damagehandling@drive-now.com';
    }

    let bcc = [] //'m.bombardi@t-econsulting.it';

    let data = {
      auth: this.userService.getCurrentUser(),
      id: item.id,
      targa: item.targa,
      nsinistro: item.sinistro_numero,
      recipient: recipient,
      cc: cc,
      bcc: bcc,
      urls: urls
    }


    return this.http.post(this.URL_BASE + 'perizie_send_parcel', data).toPromise();
  }


  get_export_url_perizie(y1, y2) {
    return this.URL_BASE + `export_perizie&token=${this.userService.getToken()}&email=${this.userService.getEmail()}&y1=${y1}&y2=${y2}`;
  }



  get_perizia_result_image_url(img: string) {
    return `${this.URL_BASE}get_perizia_result_image_url&img=${img}`;
  }

  import_perizie_xls(file: IFile): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      file: file
    }

    return this.http.post(this.URL_BASE + 'import_perizie_xls', data).toPromise();
  }


  get_costi_per_parte(vehType: string, partCode: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      vehType: vehType,
      partCode: partCode
    }

    return this.http.post(this.URL_BASE + 'get_get_costi_per_parte', data).toPromise();

  }

  get_perizia(id: number, type: string, hash = ''): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      type: type,
      hash: hash
    }

    console.log(data)

    return this.http.post(this.URL_BASE + 'get_perizia', data).toPromise();
  }



  get_perizia_pub(id: number, key): Promise<any> {
    //console.clear()
    let data = {
      id: id,
      key: key,
    }

    return this.http.post(this.URL_BASE + 'get_perizia_pub', data).toPromise();
  }
  get_perizia_result_pub(id: number, key): Promise<any> {
    let data = {
      id: id,
      key: key,
    }

    return this.http.post(this.URL_BASE + 'get_perizia_result_pub', data).toPromise();
  }

  perizie_auto_assign(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
    }


    return this.http.post(this.URL_BASE + 'perizie_auto_assign', data).toPromise();
  }

  perizie_manual_assign(id: number, assegnatoA1: string, assegnatoA2: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      assegnatoA1: assegnatoA1,
      assegnatoA2: assegnatoA2,
    }


    return this.http.post(this.URL_BASE + 'perizie_manual_assign', data).toPromise();
  }

  get_perizia_result(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'get_perizia_result', data).toPromise();
  }

  get_perizia_attachment_url(id: number, f: IFile, type: string, $fout = '') {
    return `${this.URL_BASE}get_perizia_attachment&id=${id}&hash=${f.hash}&filename=${f.fileName}&type=${type}`;
  }

  get_perizia_zip_url(id: number, type: string, header = this.getIcon(), onlyselected = false) {

    if (type == 'noval') type = 'pdf'

    return `${this.URL_BASE}get_perizia_zip_url&id=${id}&token=${this.userService.getToken()}&email=${this.userService.getEmail()}&type=${type}&header=${header}` + (onlyselected ? '&onlyselected=1' : '');
  }

  get_perizia_to_adz_url(id: number) {
    return `${this.URL_BASE}perizia_to_adz&id=${id}&token=${this.userService.getToken()}&email=${this.userService.getEmail()}`;
  }

  list_perizie(type: string, ids = '', limit = 0, stato = '', filterYear = { y1: 0, y2: 3000 }, filters = {}): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      type: type,
      ids: ids,
      limit: limit,
      stato: stato,

      filters: filters
    }

    console.log(data)

    return this.http.post(this.URL_BASE + 'list_perizie', data).toPromise();
  }

  list_storico_perizia(id: number): Promise<any> {
    return this.list_storico('perizie', id)
  }

  delete_storico(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'delete_storico', data).toPromise();
  }

  list_storico(table: string, id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      table: table,
      id: id
    }

    return this.http.post(this.URL_BASE + 'list_storico', data).toPromise();
  }

  list_logs_perizia(id: number): Promise<any> {
    return this.list_logs('perizie', id)
  }

  list_logs(table: string, id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      table: table,
      id: id
    }

    return this.http.post(this.URL_BASE + 'list_logs', data).toPromise();
  }

  upsert_perizia(item: IPerizia): Observable<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }


    const request = new HttpRequest('POST', this.URL_BASE + 'upsert_perizia', data, {
      reportProgress: true
    });



    return this.http.request(request);
  }



  perizie_contatti_list(idPerizia: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      idPerizia: idPerizia,
    }

    return this.http.post(this.URL_BASE + 'perizie_contatti_list', data).toPromise();

  }

  perizie_contatti_get(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
    }

    return this.http.post(this.URL_BASE + 'perizie_contatti_get', data).toPromise();

  }

  perizie_contatti_upsert(contatto: any): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: contatto
    }

    return this.http.post(this.URL_BASE + 'perizie_contatti_upsert', data).toPromise();

  }

  perizie_contatti_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
    }

    return this.http.post(this.URL_BASE + 'perizie_contatti_delete', data).toPromise();

  }

  upsert_perizia_accept_reject(id: number, nextStatus: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      nextStatus: nextStatus
    }

    return this.http.post(this.URL_BASE + 'upsert_perizia_accept_reject', data).toPromise();
  }


  upsert_allegati_perizia(item: IPerizia, parcella = false): Observable<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item,
      parcella: parcella
    }

    const request = new HttpRequest('POST', this.URL_BASE + 'upsert_allegati_perizia', data, {
      reportProgress: true
    });

    return this.http.request(request);
  }

  delete_perizia(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'delete_perizia', data).toPromise();
  }


  perizie_update_appointments(dirties): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      data: dirties
    }

    return this.http.post(this.URL_BASE + 'perizie_update_appointments', data).toPromise();
  }

  //////////////////////////////////////////////////////////////////////////////////////////

  list_anagrafiche(type: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      type: type
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'list_anagrafiche', data).toPromise();
  }

  get_anagrafica(id): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'get_anagrafica', data).toPromise();
  }

  upsert_anagrafica(item: IAnagrafica): Promise<IAnagrafica[]> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post<IAnagrafica[]>(this.URL_BASE + 'upsert_anagrafica', data).toPromise();
  }

  delete_anagrafica(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'delete_anagrafica', data).toPromise();
  }


  //////////////////////////////////////////////////////////////////////////////////////////




  list_utenti(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'list_utenti', data).toPromise();
  }


  utenti_get_attivo(email): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      email: email,
    }

    return this.http.post(this.URL_BASE + 'utenti_get_active', data).toPromise();
  }

  list_utenti_by_clienteAssociato(ca): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      clienteAssociato: ca
    }

    return this.http.post(this.URL_BASE + 'list_utenti_by_clienteAssociato', data).toPromise();
  }

  list_user_by_type(type: string, d = undefined, inspector_group = ''): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      type: type,
      dta: d,
      inspector_group: inspector_group
    }

    return this.http.post(this.URL_BASE + 'list_user_by_type', data).toPromise();
  }


  upsert_utente(item: IUtente): Promise<IAnagrafica[]> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post<IAnagrafica[]>(this.URL_BASE + 'upsert_utente', data).toPromise();
  }

  upsert_utente_geo(email, geo): Promise<IAnagrafica[]> {
    let data = {
      auth: this.userService.getCurrentUser(),
      email: email,
      geo: geo
    }

    return this.http.post<IAnagrafica[]>(this.URL_BASE + 'upsert_utente_geo', data).toPromise();
  }

  upsert_utente_zone(email, zones): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      email: email,
      zones: zones
    }

    return this.http.post<IAnagrafica[]>(this.URL_BASE + 'upsert_utente_zone', data).toPromise();
  }


  delete_utente(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'delete_utente', data).toPromise();
  }

  list_avvs(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'list_avvs', data).toPromise();
  }

  get_access_history(dta): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      dta: dta
    }

    return this.http.post(this.URL_BASE + 'get_access_history', data).toPromise();

  }

  get_current_user_profile(login = false): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      login: login
    }

    return this.http.post(this.URL_BASE + 'get_current_user_profile', data).toPromise();

  }

  user_change_pwd_request(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }


    return this.http.post(this.URL_BASE + 'user_change_pwd_request', data).toPromise();

  }

  user_change_pwd_confirm(hash: string, password: string): Promise<any> {
    let data = {
      token: hash,
      password: password
    }


    return this.http.post(this.URL_BASE + 'user_change_pwd_confirm', data).toPromise();

  }




  unipolrental_export_sla_eot(): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
    }

    console.log(data)
    return this.http.post(this.URL_BASE + 'unipolrental_export_sla_eot', data).toPromise();

  }

  unipolrental_export_sla_rmk(): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
    }

    console.log(data)
    return this.http.post(this.URL_BASE + 'unipolrental_export_sla_rmk', data).toPromise();

  }


  unipolrental_confirm_appointment(id, dtaAppuntamento) {

    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      dtaAppuntamento: dtaAppuntamento
    }

    console.log(data)

    return this.http.post(this.URL_BASE + 'unipolrental_confirm_appointment', data).toPromise();

  }


  unipolrental_get_stats_sla_eot(filters): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      filters: filters
    }


    console.log(data)

    return this.http.post(this.URL_BASE + 'unipolrental_get_stats_sla_eot', data).toPromise();

  }


  unipolrental_get_stats_sla_rmk(filters, commesse): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      filters: filters,
      commesse: commesse
    }


    console.log(data)

    return this.http.post(this.URL_BASE + 'unipolrental_get_stats_sla_rmk', data).toPromise();

  }

  unipolrental_get_destinatari_riaddebito(codiceCliente): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      codiceCliente: codiceCliente
    }

    return this.http.post(this.URL_BASE + 'unipolrental_get_destinatari_riaddebito', data).toPromise();

  }

  unipolrental_get_ids_by_val(val, type): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      val: val,
      type: type
    }

    return this.http.post(this.URL_BASE + 'unipolrental_get_ids_by_val', data).toPromise();

  }

  unipolrental_is_already_auth(targa): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      targa: targa
    }

    return this.http.post(this.URL_BASE + 'unipolrental_is_already_auth', data).toPromise();

  }



  unipolrental_stats_rmk(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'unipolrental_stats_rmk', data).toPromise();

  }



  unipolrental_get_vdr(id, base64): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      base64: base64
    }

    return this.http.post(this.URL_BASE + 'unipolrental_get_vdr', data).toPromise();

  }

  unipolrental_list_quick_accept(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'unipolrental_list_quick_accept', data).toPromise();

  }

  unipolrental_get_reclami_chart(period, d1, d2): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      period: period,
      d1: d1,
      d2: d2,
    }

    return this.http.post(this.URL_BASE + 'unipolrental_get_reclami_chart', data).toPromise();

  }

  unipolrental_get_stats_load(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'unipolrental_get_stats_load', data).toPromise();

  }


  unipolrental_import_rmk_from_xls(file: IFile): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      file: file
    }

    return this.http.post(this.URL_BASE + 'unipolrental_import_rmk_from_xls', data).toPromise();
  }

  unipolrental_import_rmk_sifa_from_xls(file: IFile): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      file: file
    }

    return this.http.post(this.URL_BASE + 'unipolrental_import_rmk_sifa_from_xls', data).toPromise();
  }

  unipolrental_import_rmk_sifa_cl_from_xls(file: IFile): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      file: file
    }

    return this.http.post(this.URL_BASE + 'unipolrental_import_rmk_sifa_cl_from_xls', data).toPromise();
  }

  unipolrental_get_stats_created_by(period, d1, d2, madFilter = 'both'): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      period: period,
      d1: d1,
      d2: d2,
      madFilter: madFilter
    }

    return this.http.post(this.URL_BASE + 'unipolrental_get_stats_created_by', data).toPromise();

  }

  unipolrental_get_stats_concluded_by(period, d1, d2): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      period: period,
      d1: d1,
      d2: d2
    }


    return this.http.post(this.URL_BASE + 'unipolrental_get_stats_concluded_by', data).toPromise();

  }

  unipolrental_add_temporary_appointment(idPerizia, ispettore, dta): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),

      idPerizia: idPerizia,
      ispettore: ispettore,
      dta: dta

    }

    return this.http.post(this.URL_BASE + 'unipolrental_add_temporary_appointment', data).toPromise();

  }

  unipolrental_delete_temporary_appointment(id): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'unipolrental_delete_temporary_appointment', data).toPromise();

  }

  unipolrental_get_riaddebito(id): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
    }

    return this.http.post(this.URL_BASE + 'unipolrental_get_riaddebito', data).toPromise();
  }

  unipolrental_set_riaddebito(ria): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      ria: ria,
    }
    console.log('unipolrental_set_riaddebito', ria)

    return this.http.post(this.URL_BASE + 'unipolrental_set_riaddebito', data).toPromise();
  }

  unipolrental_list_sinistri(pag, recordPerPage, targa = '', grouped = true): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      pag: pag,
      recordPerPage: recordPerPage,
      targa: targa,
      grouped: grouped,
    }

    return this.http.post(this.URL_BASE + 'unipolrental_list_sinistri', data).toPromise();
  }

  unipolrental_list_centri(showInactive = false): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      showInactive: showInactive
    }

    return this.http.post(this.URL_BASE + 'unipolrental_list_centri', data).toPromise();
  }
  unipolrental_get_centri(id): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'unipolrental_get_centri', data).toPromise();
  }

  unipolrental_upsert_centro(item): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'unipolrental_upsert_centro', data).toPromise();
  }

  unipolrental_set_coords_centri(CODICE_AZIENDALE_UNITA, lat, lng): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      CODICE_AZIENDALE_UNITA: CODICE_AZIENDALE_UNITA,
      lat: lat,
      lng: lng
    }

    return this.http.post(this.URL_BASE + 'unipolrental_set_coords_centri', data).toPromise();
  }

  unipolrental_set_riaddebito1(idPerizia, targa, CodiceContratto): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      idPerizia: idPerizia,
      targa: targa,
      CodiceContratto: CodiceContratto,
    }

    return this.http.post(this.URL_BASE + 'unipolrental_set_riaddebito1', data).toPromise();
  }

  unipolrental_upsert_reclamo(id, reclamo): Promise<any> {
    let data = {
      id: id,
      reclamo: reclamo,
    }

    return this.http.post(this.URL_BASE + 'unipolrental_upsert_reclamo', data).toPromise();
  }

  unipolrental_rispondi_reclamo(id, risposta): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      risposta: risposta,
    }

    return this.http.post(this.URL_BASE + 'unipolrental_rispondi_reclamo', data).toPromise();
  }

  unipolrental_escalation_reclamo(id, message): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      message: message
    }

    return this.http.post(this.URL_BASE + 'unipolrental_escalation_reclamo', data).toPromise();
  }


  unipolrental_get_reclamo(id): Promise<any> {
    let data = {
      id: id,
    }

    return this.http.post(this.URL_BASE + 'unipolrental_get_reclamo', data).toPromise();
  }

  unipolrental_get_reclami_stats(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser()
    }

    console.log(this)

    return this.http.post(this.URL_BASE + 'unipolrental_get_reclami_stats', data).toPromise();
  }



  unipolrental_invia_email_richiesta_targa(targa): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      targa: targa
    }

    console.log(this)

    return this.http.post(this.URL_BASE + 'unipolrental_invia_email_richiesta_targa', data).toPromise();
  }

  //////////////////////////////////////////////////////////////////////////////////////////

  list_incarichi(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'list_incarichi', data).toPromise();
  }

  get_incarico(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'get_incarico', data).toPromise();
  }

  upsert_incarico(item: IIncarico): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    // console.log(data)

    return this.http.post<any>(this.URL_BASE + 'upsert_incarico', data).toPromise();
  }

  delete_incarico(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'delete_incarico', data).toPromise();
  }

  import_conformita(file: IFile): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      file: file
    }

    return this.http.post(this.URL_BASE + 'import_conformita', data).toPromise();
  }

  import_incarichi_renault_massivo(file: IFile): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      file: file
    }

    return this.http.post(this.URL_BASE + 'import_incarichi_renault_massivo', data).toPromise();
  }

  import_incarichi_mercedes_massivo(file: IFile): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      file: file
    }

    return this.http.post(this.URL_BASE + 'import_incarichi_mercedes_massivo', data).toPromise();
  }

  getListChat(code: string, email: string): Observable<IChat[]> {

    let data = {
      auth: this.userService.getCurrentUser(),
      code: code,
      email: email
    }

    return this.http.post<IChat[]>(this.URL_BASE + 'list-chat', data);

  }


  //// DEPRECATED ///
  getListChatNotifies(): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser()
    }

    return this.http.post<IChat[]>(this.URL_BASE + 'list-chat-notifies', data).toPromise();

  }

  getListNotifies(): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser()
    }

    return this.http.post<any[]>(this.URL_BASE + 'list-notifies', data).toPromise();

  }

  addChat(chat: IChat): Promise<any> {

    if (!chat.sender) chat.sender = this.userService.getCurrentUser().email;

    let data = {
      auth: this.userService.getCurrentUser(),
      data: chat
    }

    return this.http.post<IChat[]>(this.URL_BASE + 'add-chat', data).toPromise();

  }

  //////////////////////////////////////////////////////////////////////////////////////////

  login(email: string, password: string): Promise<any> {

    return this.http.post(this.URL_BASE + 'login', { email: email, password: password }).toPromise();

  }

  get_stats(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser()
    }
    return this.http.post(this.URL_BASE + 'get_stats', data).toPromise();

  }


  get_stats_unipol_vp(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser()
    }
    return this.http.post(this.URL_BASE + 'get_stats_unipol_vp', data).toPromise();

  }





  get_stats1(d1, d2, i1, i2, cliente, assegnatoA1, assegnatoA2, dc1, dc2, prov, tipo, period = 'all', grouped = true): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),

      d1: d1,
      d2: d2,
      i1: i1,
      i2: i2,
      cliente: cliente,
      assegnatoA1: assegnatoA1,
      assegnatoA2: assegnatoA2,
      dc1: dc1,
      dc2: dc2,
      grouped: grouped,
      prov: prov,
      tipo: tipo,
      period: period,

    }

    return this.http.post(this.URL_BASE + 'get_stats1', data).toPromise();

  }


  export_stats1(d1, d2, i1, i2, cliente, assegnatoA1, assegnatoA2, dc1, dc2, prov, tipo) {


    let data = {
      auth: this.userService.getCurrentUser(),

      d1: d1,
      d2: d2,
      i1: i1,
      i2: i2,
      cliente: cliente,
      assegnatoA1: assegnatoA1,
      assegnatoA2: assegnatoA2,
      dc1: dc1,
      dc2: dc2,
      prov: prov,
      tipo: tipo,
      grouped: false

    }


    return this.http.post(this.URL_BASE + 'export_stats1', data, {
      responseType: "blob",
      headers: new HttpHeaders().append("Content-Type", "application/json")
    }).toPromise()

  }

  list_appuntamenti(ispettore = this.userService.getCurrentUser(), showId = 0, showHistory = false): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      email: ispettore,
      showId: showId,
      showHistory: showHistory
    }
    return this.http.post(this.URL_BASE + 'list_appuntamenti', data).toPromise();

  }


  ////////////////////////////////////////////////////////////////////////////////////

  list_clienti(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser()
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'list_clienti', data).toPromise();
  }

  list_marche(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser()
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'list_marche', data).toPromise();
  }

  check_targa(targa: string, id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      targa: targa,
      id: id
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'check_targa', data).toPromise();
  }

  check_telaio(telaio: string, id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      telaio: telaio,
      id: id
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'check_telaio', data).toPromise();
  }

  ////////////////////////////////////////////////////////////////////////////////////

  program_getData(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'program_getData', data).toPromise();
  }

  program_getUrl(id: string) {
    let url = this.URL_BASE + 'program_report&id=' + id;
    return url;
  }


  upsert_perizie_contabilita(item) {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    console.clear()
    console.log(data)

    return this.http.post(this.URL_BASE + 'conti_upsert_perizie_contabilita', data).toPromise();
  }

  upsert_reclami_contabilita(item) {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'conti_upsert_reclami_contabilita', data).toPromise();
  }

  getStati() {
    return [
      'BOZZA',
      'APERTO',
      'CONCLUSO SOTTO FRANCHIGIA',
      'CONCLUSO CONTROPERIZIA',
      'CONCLUSO CONFORME',
    ];
  }

  getTipoPerizia_all() {

    return [
      'RC Auto',
      'Kasco collisione',
      'Fenomeni socio-politici',
      'Furto totale con ritrovamento',
      'Incendio e scoppio',
      'RC Prodotto',
      'Rivalse',
      'Globale fabbricati',
      'Danno merci',
      'Preassuntiva',
      'Kasco completa',
      'Atti vandalici',
      'Fenomeni naturali',
      'Furto parziale',
      'Cristalli',
      'RC Professionale',
      'RC Generale',
      'Acqua condotta',
      'Merci trasportate',
      'Pratica canalizzata',
      'Atto Vandalico',
      'ID2F',
      'Furto',
      'Altre RCA',
      'Collisione',
      'Kasco',
      'Eventi Naturali - Grandine',
      'Altri Eventi Naturali',
      'Stato d\'uso',
      'unipolrental'
    ].sort((a, b) => a > b ? 1 : -1)

  }

  getTipoPerizia(type: string) {

    if (type == '*') return this.getTipoPerizia_all()

    if (type == 'ssu') return ['Stato d\'uso'];
    if (type == 'buyback') return ['Buyback'];
    if (type == 'tradein') return ['tradein'];
    if (type == 'uxpert') return ['uxpert'];
    if (type == 'unipolvp') return ['unipolvp'];
    if (type == 'unipolrental') return ['unipolrental'];

    return [
      'RC Auto',
      'Kasco collisione',
      'Fenomeni socio-politici',
      'Furto totale con ritrovamento',
      'Incendio e scoppio',
      'RC Prodotto',
      'Rivalse',
      'Globale fabbricati',
      'Danno merci',
      'Preassuntiva',
      'Kasco completa',
      'Atti vandalici',
      'Fenomeni naturali',
      'Furto parziale',
      'Cristalli',
      'RC Professionale',
      'RC Generale',
      'Acqua condotta',
      'Merci trasportate',
      'Pratica canalizzata',

      'Atto Vandalico',
      'ID2F',
      'Furto',
      'Altre RCA',
      'Collisione',
      'Kasco',
      'Eventi Naturali - Grandine',
      'Altri Eventi Naturali',

    ]
  }

  getStatiPerizia(): any[] {

    if (this.stati_perizia) {
      return this.stati_perizia;
    }

    if (!this.stati_perizia) {
      return JSON.parse(localStorage.getItem('stati_perizia'));
    }

  }


  getStatiIRepair(): any[] {
    return [
      { status: "Verifica pre-assicurativa", color: "yellow" },
      { status: "Autority sinistro", color: "red" },
      { status: "Valorizzazione degrado", color: "red" },
      { status: "Richiesta integrazione foto", color: "yellow" },
      { status: "Integrazione foto effettuata", color: "red" },
      { status: "nota da leggere", color: "red" },
      { status: "Richiesta preventivo incompleto", color: "red" },
      { status: "Preventivo incompleto bloccato", color: "red" },
      { status: "Preventivo incompleto sbloccato", color: "red" },
      { status: "Autorizzazione smontaggio", color: "red" },
      { status: "Realizzato smontaggio, nessuna integrazione", color: "red" },
      { status: "Preventivo in proposta", color: "red" },
      { status: "proposta accettata", color: "green" },
      { status: "proposta rifiutata", color: "red" }
    ]


  }

  getStato(sts, assegnatoASalaControllo = false) {

    //console.log('assegnatoASalaControllo' + assegnatoASalaControllo)
    //console.log('sts' + sts)
    let stati = this.getStatiPerizia();

    //mod per saltare passaggio sala controllo
    let sts1;
    if (sts == 'CONSEGNATO' && assegnatoASalaControllo) sts1 = this.getStato('CONSEGNATO A SALA CONTROLLO');

    //alert('sts: ' + JSON.stringify(sts))
    //alert('sts1: ' + JSON.stringify(sts1))

    for (let i = 0; i < stati.length; i++) {
      if (sts == stati[i].sts) {

        let s = stati[i];

        if (sts1) {
          s.reject = sts1.reject;
          s.reject_sendMessageTo = sts1.reject_sendMessageTo;
        }

        return s
      }
    }

    return { competence: '' };
  }

  getStatiPeriziaAll(): string[] {
    let stati = this.getStatiPerizia();

    let r = [];
    for (let i = 0; i < stati.length; i++) {
      r.push(stati[i].sts);
    }

    return r;
  }

  getStatiPeriziaAll_client(): string[] {
    let stati = this.getStatiPerizia();

    let r = [];
    for (let i = 0; i < stati.length; i++) {
      r.push(stati[i].clientDescription);
    }

    r = r.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

    return r;
  }

  getPeriziaStatusFilter(): any[] {
    let r = [];


    let arr = []

    switch (this.userService.getCurrentUser().role) {
      case 'CLIENTE':
      case 'MANDANTE':
        arr = this.getStatiPeriziaAll_client();
        break;

      default:
        arr = this.getStatiPeriziaAll();
    }


    for (let e of arr) {
      r.push({ value: e, title: e })
    }
    return r;
  }
  /*
    getAppointments(): Appointment[] {
      return appointments;
    }
  */
}
/*
let appointments: Appointment[] = [
  {
    text: "Website Re-Design Plan",
    startDate: new Date(2017, 4, 22, 9, 30),
    endDate: new Date(2017, 4, 22, 11, 30)
  }, {
    text: "Book Flights to San Fran for Sales Trip",
    startDate: new Date(2017, 4, 22, 12, 0),
    endDate: new Date(2017, 4, 22, 13, 0),
    allDay: true
  }, {
    text: "Install New Router in Dev Room",
    startDate: new Date(2017, 4, 22, 14, 30),
    endDate: new Date(2017, 4, 22, 15, 30)
  }, {
    text: "Approve Personal Computer Upgrade Plan",
    startDate: new Date(2017, 4, 23, 10, 0),
    endDate: new Date(2017, 4, 23, 11, 0)
  }, {
    text: "Final Budget Review",
    startDate: new Date(2017, 4, 23, 12, 0),
    endDate: new Date(2017, 4, 23, 13, 35)
  }, {
    text: "New Brochures",
    startDate: new Date(2017, 4, 23, 14, 30),
    endDate: new Date(2017, 4, 23, 15, 45)
  }, {
    text: "Install New Database",
    startDate: new Date(2017, 4, 24, 9, 45),
    endDate: new Date(2017, 4, 24, 11, 15)
  }, {
    text: "Approve New Online Marketing Strategy",
    startDate: new Date(2017, 4, 24, 12, 0),
    endDate: new Date(2017, 4, 24, 14, 0)
  }, {
    text: "Upgrade Personal Computers",
    startDate: new Date(2017, 4, 24, 15, 15),
    endDate: new Date(2017, 4, 24, 16, 30)
  }, {
    text: "Customer Workshop",
    startDate: new Date(2017, 4, 25, 11, 0),
    endDate: new Date(2017, 4, 25, 12, 0),
    allDay: true
  }, {
    text: "Prepare 2015 Marketing Plan",
    startDate: new Date(2017, 4, 25, 11, 0),
    endDate: new Date(2017, 4, 25, 13, 30)
  }, {
    text: "Brochure Design Review",
    startDate: new Date(2017, 4, 25, 14, 0),
    endDate: new Date(2017, 4, 25, 15, 30)
  }, {
    text: "Create Icons for Website",
    startDate: new Date(2017, 4, 26, 10, 0),
    endDate: new Date(2017, 4, 26, 11, 30)
  }, {
    text: "Upgrade Server Hardware",
    startDate: new Date(2017, 4, 26, 14, 30),
    endDate: new Date(2017, 4, 26, 16, 0)
  }, {
    text: "Submit New Website Design",
    startDate: new Date(2017, 4, 26, 16, 30),
    endDate: new Date(2017, 4, 26, 18, 0)
  }, {
    text: "Launch New Website",
    startDate: new Date(2017, 4, 26, 12, 20),
    endDate: new Date(2017, 4, 26, 14, 0)
  }
];
*/
export class Appointment {
  text: string;
  startDate: Date;
  endDate: Date;
  allDay?: boolean;
}



