
export const environment = {
    appVersion: require('../../package.json').version,
    production: true,
    envName: 'gg',
    config: {
        apiKey: "AIzaSyAzU5oJmB73yyZPryu2wOBlC_-Dukr3Fdg",
        authDomain: "direct-5620c.firebaseapp.com",
        databaseURL: "https://direct-5620c.firebaseio.com",
        projectId: "direct-5620c",
        storageBucket: "direct-5620c.appspot.com",
        messagingSenderId: "901802204342"
    }
};

