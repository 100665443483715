import { UserService } from './../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { MysqlService } from 'app/services/mysql.service';
import { Router } from '@angular/router';

import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';

declare const $: any;
declare interface RouteInfo {
    path?: string;
    name: string;
    icon: string;
    class?: string;
    separator?: boolean;
    child?: boolean;
    disabled?: boolean;
    adminOnly?: boolean;
    permissions?: string[];
    userPermissionFlag?: string;
    newPage?: boolean;

    children?: RouteInfo[];

}




/** Flat node with expandable and level information */
interface ExampleFlatNode {
    expandable: boolean;
    name: string;
    level: number;
}


@Component({
    selector: 'app-sidebar-tree',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarTreeComponent implements OnInit {
    menuItems: any[];
    categories: any[];


    user;

    logo;

    ////////////// TREE /////////////////////
    private _transformer = (node: RouteInfo, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            icon: node.icon,


            path: node.path,
            class: node.class,
            separator: node.separator,
            child: node.child,
            permissions: node.permissions,
            userPermissionFlag: node.userPermissionFlag,
            newPage: node.newPage,

            level: level,
        };
    }

    treeControl = new FlatTreeControl<ExampleFlatNode>(
        node => node.level, node => node.expandable);

    treeFlattener = new MatTreeFlattener(
        this._transformer, node => node.level, node => node.expandable, node => node.children);

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
    //////////////////////////////////////////

    TREE_DATA: RouteInfo[]

    constructor(private userService: UserService, public db: MysqlService, private router: Router) {

        this.TREE_DATA = [
            {
                name: 'CLAIMS MANAGEMENT', icon: 'commute',
                children: [
                    { path: '/claims_management/claims', name: "Claims", icon: 'security', class: '', separator: false, userPermissionFlag: 'claims_management_perizie', },

                ]
            },

            {
                name: 'AUTOMOTIVE', icon: 'commute',
                children: [

                    { path: '/automotive/dashboard', name: 'Dashboard', icon: 'dashboard', class: '', separator: false, userPermissionFlag: 'automotive_dashboard' },
                    { path: '/automotive/stats', name: 'Statistiche', icon: 'insert_chart', class: '', separator: false, userPermissionFlag: 'automotive_stats' },

                    {
                        name: 'Flussi', icon: 'sync_alt',
                        children: [

                            { path: '/automotive/perizie', name: "Insurance", icon: 'security', class: '', separator: false, userPermissionFlag: 'automotive_insurance', },
                            { path: '/automotive/ssu', name: "SSU", icon: 'opacity', class: '', separator: false, userPermissionFlag: 'automotive_ssu' },
                            { path: '/automotive/jobs', name: "Buy-back (BO)", icon: 'compare_arrows', class: '', separator: false, userPermissionFlag: 'automotive_buyback_backoffice' },
                            { path: '/automotive/buyback', name: "Buy-Back", icon: 'opacity', class: '', separator: false, userPermissionFlag: 'automotive_buyback_internal' },
                            { path: '/automotive/buyback_fleet', name: "Buy-Back (Fleet)", icon: 'opacity', class: '', separator: false, userPermissionFlag: 'automotive_buyback_fleet' },
                            { path: '/automotive/remarketing', name: "Remarketing", icon: 'local_atm', class: '', separator: false, userPermissionFlag: 'automotive_remarketing' },
                            { path: '/automotive/tradein', name: "Trade IN", icon: 'local_atm', class: '', separator: false, userPermissionFlag: 'automotive_tradein' },
                            { path: '/automotive/unipolvp', name: "Unipol VP", icon: 'local_atm', class: '', separator: false, userPermissionFlag: 'automotive_unipolvp' },
                            { path: '/automotive/psv', name: "PSV", icon: 'local_atm', class: '', separator: false, userPermissionFlag: 'automotive_psv' },
                            
                            { path: '/automotive/all', name: "Vis. tutti ", icon: 'compare_arrows', class: '', separator: false, userPermissionFlag: 'automotive_all' },
                        ]
                    },


                    { path: 'http://x-fleet.cloud', name: "X-Fleet", icon: 'directions_car', class: '', separator: false, userPermissionFlag: 'automotive_xfleet', newPage: true },

                    { path: '/ecommerce', name: "E-commerce", icon: 'shopping_cart', class: '', separator: false, userPermissionFlag: 'automotive_ecommerce', newPage: true },
                    { path: '/automotive/livexpert', name: this.db.getIcon() == 'gg-demo' || this.db.getIcon() == 'gg' ? 'DirectXpert' : 'LiveXpert', icon: 'videocam', class: '', separator: false, userPermissionFlag: 'automotive_telexpert' },
                    //{ path: '/automotive/livexpert-chime', name: this.db.getIcon() == 'gg-demo' || this.db.getIcon() == 'gg' ? 'DirectXpert-chime' : 'LiveXpert-chime', icon: 'videocam', class: '', separator: false, userPermissionFlag: 'automotive_telexpert' },
                    //{ path: '/automotive/verycar', name: "VeryCar", icon: 'perm_device_information', class: '', separator: false, userPermissionFlag: 'automotive_verycar' },
                    { path: '/automotive/irepair', name: "iRepair", icon: 'build', class: '', separator: false, userPermissionFlag: 'automotive_irepair' },
                    { path: '/automotive/planner', name: "Pianificatore", icon: 'calendar_today', class: '', separator: false, userPermissionFlag: 'automotive_calendar' },
                    { path: '/automotive/appuntamenti', name: "Appuntamenti", icon: 'calendar_today', class: '', separator: false, userPermissionFlag: 'appuntamenti' },
                    { path: '/automotive/estratti-conto', name: "Estratti Conto", icon: 'monetization_on', class: '', separator: false, userPermissionFlag: 'automotive_estratti_conto' },
                ]
            },

            {
                name: 'EOT', icon: 'commute',
                children: [

                    { path: '/unipolrental/dashboard/appuntamenti', name: 'Gest. Appuntamenti', icon: 'dashboard', class: '', separator: false, userPermissionFlag: 'unipolrental_dashboard_appuntamenti' },
                    { path: '/unipolrental/dashboard/riaddebiti', name: 'Gest. Riaddebiti', icon: 'dashboard', class: '', separator: false, userPermissionFlag: 'unipolrental_dashboard_riaddebiti' },
                    { path: '/unipolrental/dashboard/reclami', name: 'Gest. Chiarimenti', icon: 'dashboard', class: '', separator: false, userPermissionFlag: 'unipolrental_dashboard_reclami' },
                    { path: '/unipolrental/stats', name: 'Statistiche', icon: 'dashboard', class: '', separator: false, userPermissionFlag: 'unipolrental_stats' },

                    { path: '/unipolrental/flotta', name: 'Flotta', icon: 'dashboard', class: '', separator: false, userPermissionFlag: 'unipolrental_flotta' },
                    
                    { path: '/automotive/unipolrental', name: 'Ispezioni', icon: 'dashboard', class: '', separator: false, userPermissionFlag: 'unipolrental_ispezioni' },
                    { path: '/unipolrental/quick_accept', name: 'Accettazione rapida', icon: 'dashboard', class: '', separator: false, userPermissionFlag: 'unipolrental_quick_accept' },

                    { path: '/automotive/appuntamenti', name: "Calendario", icon: 'calendar_today', class: '', separator: false, userPermissionFlag: 'unipolrental_calendario' },
                    { path: '/unipolrental/gestione-centri', name: "Gestione Centri", icon: 'dashboard', class: '', separator: false, userPermissionFlag: 'unipolrental_gestione_centri' },


                ]
            },
/*
            {
                name: 'INDUSTRIAL', icon: 'apartment',
                children: [
                    { path: '/industrial/dashboard', name: 'Dashboard', icon: 'dashboard', class: '', separator: false, userPermissionFlag: 'industrial_dashboard' },

                    {
                        name: "Ispezioni Coagenti", icon: 'assignment', class: '', separator: false, userPermissionFlag: 'industrial_ispezioni',
                        children: [
                            {
                                name: "Ver. strumentali ", icon: 'assignment', class: '', separator: false, userPermissionFlag: 'industrial_ispezioni',
                                children: [
                                    { path: '/industrial/coagenti/stm/regcommerciale', name: "Commerciale", icon: 'assignment', class: '', separator: false, userPermissionFlag: 'industrial_ispezioni' },
                                    { path: '/industrial/coagenti/stm/regcommesse', name: "Commesse ", icon: 'assignment', class: '', separator: false, userPermissionFlag: 'industrial_ispezioni' },
                                    { path: '/industrial/coagenti/stm/regispettori', name: "Ispettori ", icon: 'assignment', class: '', separator: false, userPermissionFlag: 'industrial_ispezioni' },
                                ]
                            },
                            {
                                name: "Ascensori ", icon: 'assignment', class: '', separator: false, userPermissionFlag: 'industrial_ispezioni',
                                children: [
                                    { path: '/industrial/coagenti/asc/regcommerciale', name: "Commerciale", icon: 'assignment', class: '', separator: false, userPermissionFlag: 'industrial_ispezioni' },
                                    { path: '/industrial/coagenti/asc/regcommesse', name: "Commesse ", icon: 'assignment', class: '', separator: false, userPermissionFlag: 'industrial_ispezioni' },
                                    { path: '/industrial/coagenti/asc/regispettori', name: "Ispettori ", icon: 'assignment', class: '', separator: false, userPermissionFlag: 'industrial_ispezioni' },
                                ]
                            },
                            {
                                name: "Messa a terra", icon: 'assignment', class: '', separator: false, userPermissionFlag: 'industrial_ispezioni',
                                children: [
                                    { path: '/industrial/coagenti/iel/regcommerciale', name: "Commerciale", icon: 'assignment', class: '', separator: false, userPermissionFlag: 'industrial_ispezioni' },
                                    { path: '/industrial/coagenti/iel/regcommesse', name: "Commesse ", icon: 'assignment', class: '', separator: false, userPermissionFlag: 'industrial_ispezioni' },
                                    { path: '/industrial/coagenti/iel/regispettori', name: "Ispettori ", icon: 'assignment', class: '', separator: false, userPermissionFlag: 'industrial_ispezioni' },
                                ]
                            },
                            {
                                name: "Attrz. da lavoro", icon: 'assignment', class: '', separator: false, userPermissionFlag: 'industrial_ispezioni',
                                children: [
                                    { path: '/industrial/coagenti/adl/regcommerciale', name: "Commerciale", icon: 'assignment', class: '', separator: false, userPermissionFlag: 'industrial_ispezioni' },
                                    { path: '/industrial/coagenti/adl/regcommesse', name: "Commesse ", icon: 'assignment', class: '', separator: false, userPermissionFlag: 'industrial_ispezioni' },
                                    { path: '/industrial/coagenti/adl/regispettori', name: "Ispettori ", icon: 'assignment', class: '', separator: false, userPermissionFlag: 'industrial_ispezioni' },
                                ]
                            },
                        ]
                    },

                    {
                        path: '/industrial/volontari', name: "Ispezioni Volontarie", icon: 'assignment', class: '', separator: false, userPermissionFlag: 'industrial_ispezioni',

                    },


                    { path: '/industrial/planner', name: "Pianificatore", icon: 'calendar_today', class: '', separator: false, userPermissionFlag: 'industrial_calendar' },
                ]
            },
*/
            {
                name: 'MEDICAL', icon: 'local_hospital',
                children: [
                    { path: '/medical/dashboard', name: 'Dashboard', icon: 'dashboard', class: '', separator: false, userPermissionFlag: 'medical_dashboard' },
                    { path: '/medical/visite', name: 'Visite', icon: 'healing', class: '', separator: false, userPermissionFlag: 'medical_dashboard' },
                ]
            },
            {
                name: 'UXPERT', icon: 'commute',
                children: [


                    { path: '/automotive/verycar', name: "UXpert", icon: 'perm_device_information', class: '', separator: false, userPermissionFlag: 'automotive_verycar' },

                    { path: '/automotive/uxpert', name: "Perizie", icon: 'security', class: '', separator: false, userPermissionFlag: 'automotive_verycar', },

                    { path: '/options/verycar_leeds', name: "Sondaggi", icon: 'report', class: '', separator: false, userPermissionFlag: 'options_verycar_leeds' },
                    { path: '/options/verycar_defs', name: "Definizioni", icon: 'list', class: '', separator: false, userPermissionFlag: 'uxpert_verycar_defs' },
                    { path: '/options/verycar_users', name: "Utenti", icon: 'list', class: '', separator: false, userPermissionFlag: 'uxpert_verycar_users' },
                    { path: '/options/verycar_segnalazioni', name: "Segnalazioni", icon: 'list', class: '', separator: false, userPermissionFlag: 'uxpert_verycar_segnalazioni' },
                ]
            },



            {
                name: 'CONFERENCE', icon: 'home',
                children: [
                    { path: '/extra/chime-list', name: 'Rooms', icon: 'meeting_room', class: '', separator: false, userPermissionFlag: 'real_estate_rooms' },

                ]
            },

            {
                name: 'GESTIONE', icon: 'airplay',
                children: [
                    { path: '/extra/valutazioni', name: "Valutazioni", icon: 'priority_high', class: '', separator: false, userPermissionFlag: 'extra_valutazioni' },
                    { path: '/extra/valutazioni-ritiri', name: "Ritiri", icon: 'priority_high', class: '', separator: false, userPermissionFlag: 'extra_valutazioni' },
                ]
            },

            {
                name: 'ICT', icon: 'outdoor_grill',
                children: [

                    { path: '/extra/checks', name: "Checks", icon: 'fact_check', class: '', separator: false, userPermissionFlag: 'extra_checks' },

                ]
            },

            {
                name: 'EXTRA', icon: 'comment',
                children: [

                    { path: '/extra/fir', name: "FIR", icon: 'settings_ethernet', class: '', separator: false, userPermissionFlag: 'extra_fir' },
                    { path: '/extra/apeb', name: "Authority", icon: 'priority_high', class: '', separator: false, userPermissionFlag: 'extra_apeb' },
                    { path: '/extra/carserver', name: "Authority Rental", icon: 'priority_high', class: '', separator: false, userPermissionFlag: 'extra_carserver' },
                    { path: '/extra/meccanica', name: "Authority Meccanica", icon: 'priority_high', class: '', separator: false, userPermissionFlag: 'extra_authority_meccanica' },
                    
                    { path: '/extra/reclami-apeb', name: "Reclami", icon: 'priority_high', class: '', separator: false, userPermissionFlag: 'extra_apeb_reclami' },
                    { path: '/extra/mdg', name: "MdG - Allianz", icon: 'priority_high', class: '', separator: false, userPermissionFlag: 'extra_mdg' },


                    { path: '/extra/mdg-grandine', name: "MdG - Grandine", icon: 'priority_high', class: '', separator: false, userPermissionFlag: 'extra_mdggrandine' },
                    { path: '/extra/archive', name: "Archivio", icon: 'file_copy', class: '', separator: false, userPermissionFlag: 'extra_archive' },

                    { path: '/extra/chime-list', name: "Chime List", icon: 'file_copy', class: '', separator: false, userPermissionFlag: 'extra_chime' },
                ]
            },
            {
                name: 'DEMO', icon: 'comment',
                children: [

                    { path: '/demo/program', name: 'Program', icon: 'dashboard', class: '', separator: false, userPermissionFlag: 'demo_program' },

                ]
            },

            {
                name: 'OPZIONI', icon: 'settings',
                children: [
                    { path: '/options/config-azienda', name: "Azienda", icon: 'apartment', class: '', separator: false, userPermissionFlag: 'options_config_azienda' },


                    { path: '/options/users', name: "Utenti", icon: 'account_box', class: '', separator: false, userPermissionFlag: 'options_users' },
                    { path: '/options/access-history', name: "Storico accessi", icon: 'priority_high', class: '', separator: false, userPermissionFlag: 'options_access_history' },
                    { path: '/options/ecommerce-users', name: "Utenti Eshop", icon: 'account_box', class: '', separator: false, userPermissionFlag: 'options_ecommerce_users' },
                    { path: '/options/registry/', name: "Anagrafiche", icon: 'content_paste', class: '', separator: false, userPermissionFlag: 'options_registry' },

                    { path: '/options/time-matrix/', name: "Attività", icon: 'content_paste', class: '', separator: false, userPermissionFlag: 'options_time_matrix' },
                    { path: '/options/tempario/', name: "Tempario", icon: 'access_time', class: '', separator: false, userPermissionFlag: 'options_tempario' },

                    { path: '/options/valutazioni-cespiti', name: "Cespiti", icon: 'priority_high', class: '', separator: false, userPermissionFlag: 'extra_valutazioni' },
                    { path: '/options/inspector-groups', name: "Gruppi - Ispettori", icon: 'group_work', class: '', separator: false, userPermissionFlag: 'options_inspectorgroups' },
                    { path: '/options/form-composer', name: "Form composer", icon: 'priority_high', class: '', separator: false, userPermissionFlag: 'options_formcomposer' },
                    //    { path: '/options/document-composer', name: "Document composer", icon: 'priority_high', class: '', separator: false, userPermissionFlag: 'options_documentcomposer' },
                    { path: '/options/commesse', name: "Commesse", icon: 'priority_high', class: '', separator: false, userPermissionFlag: 'options_commesse' },
                    { path: '/options/coverage', name: "Copertura ispettori", icon: 'priority_high', class: '', separator: false, userPermissionFlag: 'options_coverage' },
                    { path: '/options/report', name: "Report", icon: 'priority_high', class: '', separator: false, userPermissionFlag: 'options_report' },



                ]
            },

            { path: '/info', name: 'INFO', icon: 'info', class: '', separator: false, userPermissionFlag: 'options_info' },
        ];


        this.logo = db.getLogo();
        this.dataSource.data = [];

    }

    removeNotAuthorized(nodes: RouteInfo[]): RouteInfo[] {

        let newNodes = []

        if (!nodes) return undefined;

        for (let i = 0; i < nodes.length; i++) {

            let node = nodes[i];
            let b = this.checkUserPermissionFlag(node)

            if (b) {
                node.children = this.removeNotAuthorized(node.children)
                if ((node.children && node.children.length > 0) || node.path) {
                    newNodes.push(node)
                }

            }

        }

        return newNodes;

    }

    ngOnInit() {

        console.log(this.userService.isAdmin())


        this.userService.onUserState()
            .subscribe((user: any) => {

                console.log('onUserState')

                this.db.get_current_user_profile(true).then(res => {

                    this.user = res[0]


                    let data = JSON.parse(JSON.stringify(this.TREE_DATA));
                    data = this.removeNotAuthorized(data)
                    //console.log(data)

                    //data[0].children.filter(child => child.path == '/automotive/livexpert')[0].name = this.db.getIcon()=='gg' || this.db.getIcon()=='gg-demo'  ? 'DirectXpert' : 'LiveXpert'
                    this.dataSource.data = data

                    //console.log('auth obs')
                });

            });



    }

    open(menuItem) {

        if (!menuItem.path) {

            console.log('expanded: ' + this.treeControl.isExpanded(menuItem))

            if (!this.treeControl.isExpanded(menuItem))
                this.treeControl.expand(menuItem)
            else
                this.treeControl.collapse(menuItem)
            return;
        }

        console.log(menuItem)

        if (!menuItem.newPage)
            this.router.navigate([menuItem.path])
        else {

            if (menuItem.path.startsWith('http')) {
                window.open(menuItem.path, '_blank')
            } else {
                let url = this.router.createUrlTree([menuItem.path], {})
                window.open(url.toString().replace(menuItem.path, '#' + menuItem.path), '_blank')
            }

        }

    }


    checkUserPermissionFlag(menuItem: RouteInfo): boolean {

        if (!menuItem.userPermissionFlag) return true;

        if (!this.user) return false;


        let x;
        try {
            x = this.user.permissions[menuItem.userPermissionFlag];
        } catch (error) {
            console.error(error)
            return false;
        }
        return +x > 0

    }

}
